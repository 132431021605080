import styles from './index.module.scss'
import { SaleCard } from '@/models/sale'
import { GotEntry } from '@/models/entry'
import WinText from '@/assets/result-modal/win-text.svg'
import anime from 'animejs'
import { useEffect, useMemo, useRef } from 'react'
import { Image } from '@/components/commons/image'
import { ButtonOutline } from '@/components/commons/button-outline'
import { useCallback } from 'react'
import router from 'next/router'
import { ButtonType } from '@/models/button'

type Props = {
  saleCard: SaleCard
  entry: GotEntry
  close: () => void
}

// 「当選」モーダル
export const EntryResultContentsWin = ({ saleCard, entry, close }: Props) => {
  const inner = useRef(null)

  useEffect(() => {
    anime({
      targets: inner.current,
      opacity: {
        value: [0, 1],
        easing: 'linear',
        duration: 500,
      },
      scale: [1.5, 1],
      easing: 'easeOutExpo',
      duration: 1600,
      delay: 1000,
    })
  }, [])

  const cardElement = useMemo(() => {
    return (
      <div className={styles.card_image}>
        <Image src={saleCard.card.mainImage} alt={saleCard.card.title} width={180} height={280} />
      </div>
    )
  }, [saleCard.card.mainImage, saleCard.card.title])

  const handleClick = useCallback(() => {
    router.push(`/card/user/${entry.userPlayerCardId}`)
    close()
  }, [close, entry.userPlayerCardId])

  return (
    <div className={styles.contents}>
      <div className={styles.inner} ref={inner}>
        <WinText width={288} height={38} />
        <p className={styles.head}>おめでとうございます</p>
        {cardElement}
        <p className={styles.title}>{saleCard.card.title}</p>
        <p className={styles.number}>
          Serial : {entry.serialNumber} / {saleCard.saleCount}
        </p>
        <ButtonOutline isActive={entry.userPlayerCardId !== ''} onClick={handleClick} type={ButtonType.BUTTON}>
          保有NFTカード詳細
        </ButtonOutline>
      </div>
      <i className={styles.patternRight} />
      <i className={styles.patternLeft} />
    </div>
  )
}
