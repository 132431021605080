import styles from './index.module.scss'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { SlideUpModalInner } from '@/components/root/slide-up-modal/inner'
import { useSlideUpModal } from '@/hooks/use-slide-up-modal'

export const SlideUpModal = () => {
  const { options } = useSlideUpModal()
  const [isDisplayBlock, setIsDisplayBlock] = useState<boolean>(false)
  // animation 用の element の参照
  const bgAnimationElement = useRef<HTMLDivElement>(null)

  const isShow = useMemo<boolean>(() => {
    return options !== undefined
  }, [options])

  const contentsElement = useMemo<JSX.Element | undefined>(() => {
    if (!options) return
    return options.contents
  }, [options])

  const handleClose = useCallback(() => {
    if (!options) return
    if (!options.callback) return
    options.callback()
  }, [options])

  // 表示時はすぐ display: block にする
  useEffect(() => {
    if (options) {
      setIsDisplayBlock(true)
    }
  }, [options])

  // display: none にするタイミングは animation の後
  const handleTransitionExited = useCallback(() => {
    setIsDisplayBlock(false)
  }, [])

  return (
    <div className={classNames(styles.root, { [styles.isShow]: isDisplayBlock })}>
      <CSSTransition
        in={isShow}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.bgEnter,
          enterActive: styles.bgEnterActive,
          exit: styles.bgExit,
          exitActive: styles.bgExitActive,
        }}
        nodeRef={bgAnimationElement}
        onExited={handleTransitionExited}
      >
        <div className={styles.bg} ref={bgAnimationElement} />
      </CSSTransition>

      <SlideUpModalInner onClose={handleClose} contents={contentsElement} />
    </div>
  )
}
