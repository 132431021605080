import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export function mapDate(data: Timestamp.AsObject): Date {
  return new Date(data.seconds * 1000)
}

export function mapTimestamp(date: Date): Timestamp {
  const item = new Timestamp()
  item.setSeconds(date.getTime() / 1000)
  return item
}
