import { Team } from '@/models/team'
import { GameDeck, GameDeckResult } from '@/models/deck'
import { GameRank, GameRanking } from '@/models/game-rank'

export const GameStatus = {
  WAIT_PREDICTION: 1, // 予想開始待ち
  START_PREDICTION: 2, // 予想中
  END_PREDICTION: 3, // 予想期間終了、試合開始待ち
  START_MATCH: 4, // 試合中
  START_AGGREGATION: 5, // 集計中
  END_MATCH: 6, // 試合終了
  CANCEL_MATCH: 7, // 試合キャンセル
} as const
export type GameStatus = typeof GameStatus[keyof typeof GameStatus]

export const GamePeriod = {
  Q0: 0, // 試合前
  Q1: 1,
  Q2: 2,
  Q3: 3,
  Q4: 4,
  EX: 5,
} as const
export type GamePeriod = typeof GamePeriod[keyof typeof GamePeriod]

export interface Game {
  id: string
  date: Date // 試合開始日
  title: string
  year: number // 年度
  roundName: string // 節名
  period: GamePeriod
  homeTeamId: string
  homeTeam: Team
  awayTeamId: string
  awayTeam: Team
  winLose: string
  homeScoreTotal: number
  awayScoreTotal: number
  arenaId: string
  arenaName: string
  gameStatus: GameStatus
  gameDeck?: GameDeck
  gameDeckResult?: GameDeckResult
  gameRank?: GameRank // 現在のランク
  gamePlayerCount: number
  publishStartAt: Date
  publishEndAt: Date
  predictionStartAt: Date // 予想開始日
  predictionEndAt: Date // 予想終了日
  gameRanking?: GameRanking
}

export interface GameResultFirestoreData {
  gameId: string
  period: GamePeriod
  captainCardPoint: number
  q1CardPoint: number
  q2CardPoint: number
  q3CardPoint: number
  q4CardPoint: number
  rank: number
  point: number
  gameStatus: GameStatus
}
