// 全体お知らせ
export interface GeneralNews {
  id: string
  title: string
  body: string
  thumbnailUrl?: string
  imageUrl?: string
  publishStartAt: Date
  publishEndAt: Date
  url: string
  createdAt: Date
  updatedAt?: Date
}

// 個別のお知らせ
export interface UserNews {
  id: string
  title: string
  body: string
  thumbnailUrl?: string
  imageUrl?: string
  url: string
  isAlreadyRead: boolean
  createdAt: Date
}

export type News = GeneralNews | UserNews

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUserNews(value: any): value is UserNews {
  return value && typeof value === 'object' && typeof value.isAlreadyRead === 'boolean' // 既読処理は UserNews にしかない
}
