import { FirebaseApp, initializeApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'
import { Firestore, getFirestore } from 'firebase/firestore'

type FirebaseConfig = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
}

const { NEXT_PUBLIC_FIREBASE_CONFIG } = process.env

let firebaseApp: FirebaseApp | undefined

export function initFirebase(): void {
  // すでに初期化時
  if (firebaseApp) {
    return
  }

  if (!NEXT_PUBLIC_FIREBASE_CONFIG) {
    throw new Error('undefined fiebase config env')
  }

  const configEnv = NEXT_PUBLIC_FIREBASE_CONFIG.replace(/(\\|\n|\s)/g, '')
  const config = JSON.parse(configEnv) as FirebaseConfig

  firebaseApp = initializeApp(config)
}

export function getFirebaseApp(): FirebaseApp {
  if (!firebaseApp) {
    throw new Error('Not firebase initialized')
  }

  return firebaseApp
}

export function getAuthClient(): Auth {
  return getAuth(getFirebaseApp())
}

export function getFirestoreClient(): Firestore {
  return getFirestore(getFirebaseApp())
}
