import { atom, useRecoilState } from 'recoil'
import { useCallback } from 'react'
import { InterFaceModal } from '@/interfaces/modal'

type SuccessModalOptions = {
  title: string
  description?: string
  callback: () => void
}

const successModalState = atom<SuccessModalOptions | undefined>({
  key: 'state/success-modal',
  default: undefined,
})

export const useSuccessModal = (): InterFaceModal<SuccessModalOptions> => {
  const [options, setOptions] = useRecoilState(successModalState)

  const open = useCallback(
    (options: SuccessModalOptions) => {
      setOptions(options)
    },
    [setOptions],
  )
  const close = useCallback(() => {
    setOptions(undefined)
  }, [setOptions])

  return {
    options,
    open,
    close,
  }
}
