import { Image as HoopImage } from '@dena/hoop-proto-client/dist/common_pb'

export function mapImage(data: HoopImage.AsObject): string {
  // 仕様メモ
  // デバイスピクセル比によって表示する画像の解像度を変える
  // https://developer.mozilla.org/ja/docs/Web/API/Window/devicePixelRatio
  if (window.devicePixelRatio <= 2) {
    return data.size2x
  } else {
    return data.size3x
  }
}
