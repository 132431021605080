/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GeneralNews, UserNews } from '@/models/news'
import { News as HoopNews, Notification as HoopNotification } from '@dena/hoop-proto-client/dist/notifications_entity_pb'
import dayjs from 'dayjs'
import { mapDate } from '../parser'

export function mapGeneralNews(data: HoopNews.AsObject | undefined): GeneralNews | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title.replace(/\\\\n/g, '\n'),
    body: data.body.replace(/\\\\n/g, '\n'),
    thumbnailUrl: data.thumbnailUrl ? data.thumbnailUrl.size2x : undefined,
    imageUrl: data.imageUrl ? data.imageUrl.size2x : undefined,
    publishStartAt: mapDate(data.publishStartAt!),
    publishEndAt: mapDate(data.publishEndAt!),
    url: data.url,
    createdAt: mapDate(data.publishStartAt!), // memo: ソート用にpublishStartAtを使う
    updatedAt: data.updatedAt ? mapDate(data.updatedAt) : undefined,
  }
}

export function mapNotification(data: HoopNotification.AsObject | undefined): UserNews | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title.replace(/\\\\n/g, '\n'),
    body: data.body.replace(/\\\\n/g, '\n'),
    thumbnailUrl: data.thumbnailUrl ? data.thumbnailUrl.size2x : undefined,
    imageUrl: data.imageUrl ? data.imageUrl.size2x : undefined,
    url: data.url,
    createdAt: dayjs(data.createdAt).toDate(),
    isAlreadyRead: data.isRead,
  }
}
