/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GeneralNews, UserNews } from '@/models/news'
import { grpc } from '@improbable-eng/grpc-web'
import { NotificationsClient } from '@dena/hoop-proto-client/dist/notifications_pb_service'
import { ListPublishedNewsRequest } from '@dena/hoop-proto-client/dist/notifications_pb'
import { HoopErrorUnknown } from '../error'
import { mapGeneralNews } from './parser'
import { list as listUserNews } from '@/lib/firebase/user-news'

type Response = {
  generalNews: GeneralNews[]
  userNews: UserNews[]
}

export async function listNews(token: string, uid: string): Promise<Response> {
  const generalNews = await listGeneralNews(token)
  const userNews = await listUserNews(uid)

  return {
    generalNews,
    userNews,
  }
}

function listGeneralNews(token: string): Promise<GeneralNews[]> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new ListPublishedNewsRequest()

    const metadata = new grpc.Metadata()
    metadata.append('authorization', `bearer ${token}`)

    const client = new NotificationsClient(host)

    client.listPublishedNews(request, metadata, (error, response) => {
      if (error) {
        reject(new HoopErrorUnknown(error.message))
        return
      }

      if (!response) {
        reject(new HoopErrorUnknown('ListNewsRequest no response'))
        return
      }

      const data = response.toObject()
      console.info('ListNewsRequest: ', data)

      const items = data.newsList.map<GeneralNews>((item) => mapGeneralNews(item)!)

      resolve(items)
    })
  })
}
