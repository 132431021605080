import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import styles from './index.module.scss'

type Props = {
  src: string
  webp?: string
  width?: number
  height?: number
  alt?: string
  onError?: () => void
}

const ImageElement = ({ src, webp, width, height, alt, onError }: Props) => {
  const [isShowImage, setIsShowImage] = useState<boolean>(false)
  const imageElement = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (!imageElement.current) {
      return
    }

    // cache があるときは、srcセット時に時に完了になってる
    if (imageElement.current.complete) {
      setIsShowImage(true)
    }
  }, [])

  const handleLoaded = () => {
    setIsShowImage(true)
  }

  const handleError = () => {
    if (!onError) return
    onError()
  }

  const classes = useMemo(() => {
    if (!isShowImage) {
      return classNames(styles.root)
    }

    return classNames(styles.root, styles.show)
  }, [isShowImage])

  return (
    <div className={classes} style={{ width, height }}>
      <picture>
        {webp ? <source srcSet={webp} type="image/webp" width={width} height={height} /> : undefined}
        <img src={src} width={width} height={height} alt={alt} ref={imageElement} loading="lazy" onLoad={handleLoaded} onError={handleError} />
      </picture>
    </div>
  )
}

export {
  // MEMO: javascript の new Image() と conflict するので、exportのタイミングで named する
  ImageElement as Image,
}
