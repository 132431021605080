import { useCallback } from 'react'
import { sendGtagPageview, sendGtagEvent, setGtagUserId } from '@/lib/gtag'

// GA 送信用　hook
export function useAnalytics() {
  const setUserIdForAnalytics = useCallback((firebaseUid: string) => {
    setGtagUserId(firebaseUid)
  }, [])

  const sendPageview = useCallback((path: string) => {
    sendGtagPageview(path)
  }, [])

  const sendEvent = useCallback((type: 'click', event: { category: string; label: string }) => {
    sendGtagEvent(type, event)
  }, [])

  return {
    setUserIdForAnalytics,
    sendPageview,
    sendEvent,
  }
}
