/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GameDeck, GameDeckResult } from '@/models/deck'
import { Game, GamePeriod, GameStatus } from '@/models/game'
import { GameRank, GameRanking } from '@/models/game-rank'
import { Game as HoopGame, GameStatus as HoopGameStatus, GameDeck as HoopGameDeck, GameRank as HoopGameRank, GameRanking as HoopGameRanking } from '@dena/hoop-proto-client/dist/hoop_entity_pb'
import { mapDate, mapTeam, mapPlayerCard } from '../parser'

export function mapGameStatus(value: number): GameStatus | undefined {
  switch (value) {
    // 予想開始待ち
    case HoopGameStatus.GAME_STATUS_WAIT_PREDICTION:
      return GameStatus.WAIT_PREDICTION
    // 予想中
    case HoopGameStatus.GAME_STATUS_START_PREDICTION:
      return GameStatus.START_PREDICTION
    // 予想期間終了、試合開始待ち
    case HoopGameStatus.GAME_STATUS_END_PREDICTION:
      return GameStatus.END_PREDICTION
    // 試合中
    case HoopGameStatus.GAME_STATUS_START_MATCH:
      return GameStatus.START_MATCH
    // 集計中
    case HoopGameStatus.GAME_STATUS_START_AGGREGATION:
      return GameStatus.START_AGGREGATION
    // 試合終了
    case HoopGameStatus.GAME_STATUS_END_MATCH:
      return GameStatus.END_MATCH
    // 試合キャンセル
    case HoopGameStatus.GAME_STATUS_CANCEL_MATCH:
      return GameStatus.CANCEL_MATCH
    default:
      return
  }
}

function mapDeck(data: HoopGameDeck.AsObject): GameDeck | undefined {
  if (!data.captainCard || !data.quarter1Card || !data.quarter2Card || !data.quarter3Card || !data.quarter4Card) {
    return
  }

  return {
    gameId: data.gameId,
    captain: mapPlayerCard(data.captainCard)!,
    q1: mapPlayerCard(data.quarter1Card)!,
    q2: mapPlayerCard(data.quarter2Card)!,
    q3: mapPlayerCard(data.quarter3Card)!,
    q4: mapPlayerCard(data.quarter4Card)!,
  }
}

function mapDeckResult(data: HoopGameDeck.AsObject): GameDeckResult | undefined {
  if (!data.captainCard || !data.quarter1Card || !data.quarter2Card || !data.quarter3Card || !data.quarter4Card) {
    return
  }

  return {
    gameId: data.gameId,
    captain: mapPlayerCard(data.captainCard)!,
    captainCardPoint: data.captainCardPoint,
    q1: mapPlayerCard(data.quarter1Card)!,
    q1CardPoint: data.quarter1CardPoint,
    q2: mapPlayerCard(data.quarter2Card)!,
    q2CardPoint: data.quarter2CardPoint,
    q3: mapPlayerCard(data.quarter3Card)!,
    q3CardPoint: data.quarter3CardPoint,
    q4: mapPlayerCard(data.quarter4Card)!,
    q4CardPoint: data.quarter4CardPoint,
  }
}

export function mapGameRank(data: HoopGameRank.AsObject): GameRank | undefined {
  if (!data.gameDeck) {
    return
  }

  const deck = mapDeck(data.gameDeck)
  if (!deck) {
    return
  }

  return {
    gameId: data.gameId,
    userId: data.userId,
    userName: data.userName,
    nickname: data.nickname,
    rank: data.rank,
    point: data.point,
    pickfivePoint: data.earnedPoint,
  }
}

export function mapGameRanking(data: HoopGameRanking.AsObject): GameRanking | undefined {
  if (!data.gameRanksList) {
    return
  }

  const gameRanks: GameRank[] = []
  for (const gameRank of data.gameRanksList.map((gameRank) => mapGameRank(gameRank))) {
    if (gameRank !== undefined) {
      gameRanks.push(gameRank)
    }
  }

  return {
    gameId: data.gameId,
    totalCount: data.totalCount,
    gameRanks,
  }
}

export function mapPeriod(value: number): GamePeriod | undefined {
  if (isNaN(value)) {
    return
  }

  switch (value) {
    case 0:
      return GamePeriod.Q0
    case 1:
      return GamePeriod.Q1
    case 2:
      return GamePeriod.Q2
    case 3:
      return GamePeriod.Q3
    case 4:
      return GamePeriod.Q4
    default:
      if (value >= 5) {
        return GamePeriod.EX
      } else {
        return
      }
  }
}

export function mapGame(data: HoopGame.AsObject): Game {
  return {
    id: data.id,
    date: mapDate(data.date!),
    title: data.title,
    year: data.year,
    roundName: data.roundName,
    period: mapPeriod(data.period)!,
    homeTeamId: data.homeTeamId,
    homeTeam: mapTeam(data.hometeam!),
    awayTeamId: data.awayTeamId,
    awayTeam: mapTeam(data.awayteam!),
    winLose: data.winLose,
    homeScoreTotal: data.homeScoreTotal,
    awayScoreTotal: data.awayScoreTotal,
    arenaId: data.arenaId,
    arenaName: data.arenaName,
    gameStatus: mapGameStatus(data.gameStatus)!,
    gameDeck: data.gameDeck ? mapDeck(data.gameDeck) : undefined,
    gameDeckResult: data.gameDeck ? mapDeckResult(data.gameDeck) : undefined,
    gameRank: data.gameRank ? mapGameRank(data.gameRank) : undefined,
    gamePlayerCount: data.gamePlayerCount,
    publishStartAt: mapDate(data.publishStartAt!),
    publishEndAt: mapDate(data.publishEndAt!),
    predictionStartAt: mapDate(data.predictionStartAt!),
    predictionEndAt: mapDate(data.predictionEndAt!),
    gameRanking: data.gameRanking ? mapGameRanking(data.gameRanking) : undefined,
  }
}
