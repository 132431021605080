import { atom, useRecoilState } from 'recoil'
import { useCallback } from 'react'
import { InterFaceModal } from '@/interfaces/modal'

type GotemModalOptions = {
  contents: JSX.Element
  callback?: () => void
}

const gotemModalElement = atom<GotemModalOptions | undefined>({
  key: 'state/gotem-modal-element',
  default: undefined,
})

export const useGotemModal = (): InterFaceModal<GotemModalOptions> => {
  const [options, setOptions] = useRecoilState(gotemModalElement)

  const close = useCallback(() => {
    setOptions(undefined)
  }, [setOptions])

  const open = useCallback(
    (options: GotemModalOptions) => {
      if (!options.callback) {
        options.callback = () => {
          close()
        }
      }
      setOptions(options)
    },
    [close, setOptions],
  )

  return {
    options,
    open,
    close,
  }
}
