import { useRoot } from '@/hooks/pages/use-root'
import { useAnalytics } from '@/hooks/use-analytics'
import { useSiteData } from '@/hooks/use-site-data'
import { useSentry } from '@/hooks/use-sentry'
import router from 'next/router'
import { useEffect, useMemo } from 'react'

type Props = {
  children: React.ReactNode
}

export const Root = ({ children }: Props) => {
  const { state, getSiteData } = useRoot()
  const { sendPageview, setUserIdForAnalytics } = useAnalytics()
  const { setUserIdforSentry, unsetUserIdforSentry } = useSentry()
  const { firebaseUid } = useSiteData()

  useEffect(() => {
    getSiteData()
  }, [getSiteData])

  // pageview
  useEffect(() => {
    const handleRouteChange = (path: string) => {
      sendPageview(path)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [sendPageview])

  // set gtag user id
  useEffect(() => {
    if (!firebaseUid) {
      unsetUserIdforSentry()
      return
    }

    setUserIdForAnalytics(firebaseUid)
    setUserIdforSentry(firebaseUid)
  }, [firebaseUid, setUserIdForAnalytics])

  const contentElement = useMemo<JSX.Element | undefined>(() => {
    if (!state?.result) {
      return
    }

    return <>{children}</>
  }, [children, state?.result])

  return <>{contentElement}</>
}
