import { doc, FirestoreDataConverter, QueryDocumentSnapshot, DocumentData, getDoc } from 'firebase/firestore'
import { getFirestoreClient } from '@/lib/firebase/'
import { Profile, SnsData } from '@/models/profile'

const converter: FirestoreDataConverter<Profile> = {
  toFirestore: (model: Profile): DocumentData => {
    return model
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot): Profile => {
    const data = snapshot.data()
    let snsData: SnsData = {
      instagramId: '',
      twitterId: '',
    }
    if (data.SnsLink) {
      snsData = {
        instagramId: data.SnsLink.InstagramID ? data.SnsLink.InstagramID : '',
        twitterId: data.SnsLink.TwitterID ? data.SnsLink.TwitterID : '',
      }
    }

    return {
      username: data.UserName,
      nickname: data.Nickname,
      description: data.Description ? data.Description : '',
      iconImage: data.IconImage ? data.IconImage.Size2xUrl : undefined,
      backgroundImage: data.BackGroundImage ? data.BackGroundImage.Size2xUrl : undefined,
      snsLink: snsData,
    }
  },
}

export async function getPrivateProfile(uid: string): Promise<Profile | undefined> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'profiles', 'private').withConverter(converter)
  const snapshot = await getDoc(query)
  const data = snapshot.data()

  return data
}

export async function getPublicProfile(uid: string): Promise<Profile | undefined> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'profiles', 'public').withConverter(converter)
  const snapshot = await getDoc(query)
  const data = snapshot.data()

  return data
}
