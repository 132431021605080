export const ButtonType = {
  LINK: 'Link',
  BUTTON: 'Button',
} as const
export type ButtonType = typeof ButtonType[keyof typeof ButtonType]

export interface LinkProps {
  type: 'Link'
  children: React.ReactNode
  isActive: boolean
  href: string
}

export interface ButtonProps {
  type: 'Button'
  children: React.ReactNode
  isActive: boolean
  isLoading?: boolean
  onClick: () => void
}
