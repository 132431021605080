import styles from './index.module.scss'
import { useMemo, useRef, useEffect, useCallback } from 'react'
import { useGotemModal } from '@/hooks/use-gotem-modal'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import { CSSTransition } from 'react-transition-group'
import Close from '@/assets/icons/close.svg'
import anime from 'animejs'

export const GotemModal = () => {
  const nodeRef = useRef(null)
  const body = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()
  const { options } = useGotemModal()

  const isOpen = useMemo(() => {
    return options !== undefined
  }, [options])

  const contentsElement = useMemo(() => {
    if (!options) return
    return options.contents
  }, [options])

  const handleClick = useCallback(() => {
    if (!options) return
    if (!options.callback) return
    options.callback()
  }, [options])

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (isOpen) {
      scrollTarget(scrollElement.current)
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [isOpen, scrollTarget, unScrollTarget])

  useEffect(() => {
    if (!isOpen) return
    anime({
      targets: body.current,
      opacity: {
        value: [0, 1],
        easing: 'linear',
        duration: 500,
      },
      scale: [1.5, 1],
      easing: 'easeOutExpo',
      duration: 1600,
      delay: 1000,
    })
  }, [isOpen])

  return (
    <CSSTransition
      in={isOpen}
      timeout={{
        enter: 0,
        exit: 500,
      }}
      unmountOnExit
      classNames={{
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      nodeRef={nodeRef}
    >
      <div className={styles.container} ref={nodeRef}>
        <div className={styles.inner} ref={scrollElement}>
          <button className={styles.button_close} onClick={handleClick}>
            <Close width={24} height={24} />
          </button>
          <div className={styles.contents} ref={body}>
            {contentsElement}
          </div>
          <i className={styles.patternRight} />
          <i className={styles.patternLeft} />
        </div>
      </div>
    </CSSTransition>
  )
}
