import { setPersistence, browserLocalPersistence, signInWithCustomToken, User, signOut, onAuthStateChanged } from 'firebase/auth'
import { HoopErrorUnknown } from '@/lib/error'

import { getAuthClient } from '@/lib/firebase/'

export const loginFirebase = async (customToken: string): Promise<User> => {
  try {
    const auth = getAuthClient()
    await setPersistence(auth, browserLocalPersistence)
    const userCredential = await signInWithCustomToken(auth, customToken)

    if (!userCredential.user) {
      throw new HoopErrorUnknown('failed get login user')
    }
    return userCredential.user
  } catch (error) {
    throw new HoopErrorUnknown('faied firebase login')
  }
}

export const logoutFirebase = async () => {
  const auth = getAuthClient()
  await signOut(auth)
}

const getLoginUser = async (): Promise<User | undefined> => {
  return new Promise((resolve) => {
    const auth = getAuthClient()

    if (auth.currentUser !== null) {
      resolve(auth.currentUser)
      return
    }

    const unwatch = onAuthStateChanged(
      auth,
      (user) => {
        resolve(user || undefined)
        unwatch()
      },
      (error) => {
        console.error(error)
      },
    )
  })
}

export const getFirebaseLoginToken = async (): Promise<string | undefined> => {
  const firebaseUser = await getLoginUser()
  if (!firebaseUser) {
    return
  }

  return await firebaseUser.getIdToken(true)
}

export const getFirebaseLoginUid = async (): Promise<string | undefined> => {
  const firebaseUser = await getLoginUser()
  if (!firebaseUser) {
    return
  }

  return firebaseUser.uid
}
