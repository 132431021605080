import { grpc } from '@improbable-eng/grpc-web'
import { BlockChain } from '@dena/hoop-proto-client/dist/blockchain_pb_service'
import { HasWalletAddressRequest, HasWalletAddressResponse } from '@dena/hoop-proto-client/dist/blockchain_pb'
import { HoopErrorUnknown } from '@/lib/error'

export default function hasWalletAddress(token: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new Error('failed get api host'))
      return
    }

    const request = new HasWalletAddressRequest()

    grpc.unary(BlockChain.HasWalletAddress, {
      request,
      host,
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as HasWalletAddressResponse.AsObject
          console.info('HasWalletAddressRequest data = ', data)
          resolve(data.hasWalletAddress)
        } else {
          reject(new HoopErrorUnknown(`failed HasWalletAddressRequest: status = ${status}`))
        }
      },
    })
  })
}
