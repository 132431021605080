import { SaleCard } from '@/models/sale'

// カードを獲得していない申込（serialNumber も userPlayerCardId も持たない）
export interface NotGettingEntry {
  saleId: string
  entryStatus: '結果発表待ち' | '落選'
  enteredAt: Date
}

// カード獲得を待機している申込（serialNumber は持つが userPlayerCardId は持たない）
export interface AWaitingArrivalEntry {
  saleId: string
  entryStatus: '発行処理中'
  enteredAt: Date
  serialNumber: number
}

// カード獲得した申込（serialNumber も userPlayerCardId を持つ）
export interface GotEntry {
  saleId: string
  entryStatus: '当選' | '購入済み'
  enteredAt: Date
  serialNumber: number
  userPlayerCardId: string
}

export type Entry = NotGettingEntry | AWaitingArrivalEntry | GotEntry

export interface EntryDetail {
  saleCard: SaleCard
  entry: Entry
}

// 申込ステータス
export const EntryStatus = {
  WAITING_DRAW_LOTS: '結果発表待ち',
  RESULT_WIN: '当選',
  RESULT_LOSE: '落選',
  AWAITING_ARRIVAL: '発行処理中',
  SOLD: '購入済み',
} as const
export type EntryStatus = typeof EntryStatus[keyof typeof EntryStatus]
