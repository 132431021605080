import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { newsListState } from '@/recoil/news-list-atom'

export function useNewsList() {
  const [isShowNewsList, setIsShowNewsList] = useRecoilState(newsListState)

  const showNewsList = useCallback(() => {
    setIsShowNewsList(true)
  }, [setIsShowNewsList])

  const hideNewsList = useCallback(() => {
    setIsShowNewsList(false)
  }, [setIsShowNewsList])

  return {
    isShowNewsList,
    showNewsList,
    hideNewsList,
  }
}
