import styles from './item.module.scss'
import { News, isUserNews } from '@/models/news'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import RightIcon from '@/assets/icons/angle-right.svg'

type Props = {
  item: News
  onClick: (item: News) => void
}

export const NewsListItem = ({ item, onClick }: Props) => {
  const date = useMemo(() => {
    if (isUserNews(item)) {
      return dayjs(item.createdAt).format('YYYY.M.D (ddd)')
    } else {
      return dayjs(item.publishStartAt).format('YYYY.M.D (ddd)')
    }
  }, [item])

  return (
    <div className={styles.item} onClick={() => onClick(item)}>
      <div className={styles.inner}>
        <p className={styles.title}>{item.title}</p>
        <p className={styles.date}>{date}</p>
      </div>
      <RightIcon width={24} height={24} />
    </div>
  )
}
