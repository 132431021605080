/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Team } from '@/models/team'
import { Team as HoopTeam } from '@dena/hoop-proto-client/dist/hoop_entity_pb'
import { mapImage } from '../parser'

export function mapTeam(data: HoopTeam.AsObject): Team {
  return {
    id: data.id,
    bleagueTeamId: data.bleagueTeamId,
    league: data.league,
    name: data.name,
    pref: data.pref,
    logoUrl: mapImage(data.logoUrl!),
    thumbnailUrl: mapImage(data.thumbnailUrl!),
  }
}
