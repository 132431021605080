import styles from './index.module.scss'
import { useMemo, useRef, useEffect } from 'react'
import { useErrorModal } from '@/hooks/use-error-modal'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import { CSSTransition } from 'react-transition-group'
import Exclamation from '@/assets/icons/modal/error/exclamation.svg'

export const ErrorModal = () => {
  const nodeRef = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()
  const { options } = useErrorModal()

  const isOpen = useMemo(() => {
    return options !== undefined
  }, [options])

  const handleClick = () => {
    if (!options) return
    if (!options.callback) return
    options.callback()
  }

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (options) {
      scrollTarget(scrollElement.current)
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [options, scrollTarget, unScrollTarget])

  return (
    <CSSTransition
      in={isOpen}
      timeout={{
        enter: 0,
        exit: 500,
      }}
      unmountOnExit
      classNames={{
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      nodeRef={nodeRef}
    >
      <div className={styles.container} ref={nodeRef}>
        <div className={styles.inner} ref={scrollElement}>
          <div className={styles.contents}>
            <div className={styles.image}>
              <Exclamation width={54} height={54} />
            </div>
            <div className={styles.texts}>
              <p className={styles.title}>{options?.title}</p>
              <p className={styles.description}>{options?.description}</p>
            </div>
            <button className={styles.button} onClick={handleClick}>
              OK
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
