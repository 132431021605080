import { Rarity } from '@/models/rarity'
import { Player } from '@/models/player'

export const TeamsShortName = {
  YOKOHAMA_B_CORSAIRS: '横浜',
  NIIGATA_ALBIREX_BB: '新潟',
  TOYAMA_GROUSES: '富山',
  SHIGA_LAKESTARS: '滋賀',
  AKITA_NORTHERN_HAPPINETS: '秋田',
  SAN_EN_NEOPHOENIX: '三遠',
  OSAKA_EVESSA: '大阪',
  KYOTO_HANNARYZ: '京都',
  LEVANGA_HOKKAIDO: '北海道',
  RYUKYU_GOLDEN_KINGS: '琉球',
  UTSUNOMIYA_BRE: '宇都宮',
  KAWASAKI_BRAVE_THUNDERS: '川崎',
  ALVARK_TOKYO: 'A東京',
  CHIBA_JETS: '千葉',
  NAGOYA_DIAMOND_DOLPHINS: '名古屋D',
  GUNMA_CRANE_THUNDERS: '群馬',
  SEAHORSES_MIKAWA: '三河',
  IBARAKI_ROBOTS: '茨城',
  HIROSHIMA_DRAGONFLIES: '広島',
  SHINSHU_BRAVE_WARRIORS: '信州',
  SHIMANE_SUSANOO_MAGIC: '島根',
  SUNROCKERS_SHIBUYA: 'SR渋谷',
  SENDAI_89ERS: '仙台',
  FIGHTING_EAGLES_NAGOYA: 'FE名古屋',
  SAGA_BALLOONERS: '佐賀',
  NAGASAKI_VELCA: '長崎',
} as const
export type TeamsShortName = typeof TeamsShortName[keyof typeof TeamsShortName]

export interface PlayerStats {
  date?: Date
  homeTeamShortName?: TeamsShortName
  awayTeamShortName?: TeamsShortName
  starting: boolean
  totalPlayTime: string
  q1PlayTime: number
  q2PlayTime: number
  q3PlayTime: number
  q4PlayTime: number
  captainEff: number
  q1Eff: number
  q2Eff: number
  q3Eff: number
  q4Eff: number
}

// 選手カード
export interface PlayerCard {
  id: string
  title: string
  description: string
  season: string
  series: string
  tokenType: string
  tokenName: string
  rarity: Rarity
  teamId: string
  playerId: string
  player: Player
  thumbnailUrl: string
  mainImageUrl: string
  mainVideoUrl: string
  captainOdds: string
  q1Odds: string
  q2Odds: string
  q3Odds: string
  q4Odds: string
  rateUpdatedAt?: Date
  youtubeVideoId: string
  createdAt: Date
  statsList: PlayerStats[]
}
