/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PlayerCard, PlayerStats, TeamsShortName } from '@/models/player-card'
import { PlayerCard as HoopPlayerCard } from '@dena/hoop-proto-client/dist/hoop_entity_pb'
import { mapRarity, mapPlayer, mapImage, mapDate } from '../parser'

export function mapPlayerCard(data: HoopPlayerCard.AsObject | undefined): PlayerCard | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title,
    description: data.desc,
    season: data.season,
    series: data.series,
    tokenType: data.itemTokenType,
    tokenName: data.itemTokenName,
    rarity: mapRarity(data.rarity)!,
    teamId: data.teamId,
    playerId: data.playerId,
    player: mapPlayer(data.player)!,
    thumbnailUrl: mapImage(data.thumbnailUrl!),
    mainImageUrl: mapImage(data.mainImageUrl!),
    mainVideoUrl: data.mainVideoUrl,
    captainOdds: data.captainOdds,
    q1Odds: data.q1Odds,
    q2Odds: data.q2Odds,
    q3Odds: data.q3Odds,
    q4Odds: data.q4Odds,
    rateUpdatedAt: data.rateUpdatedAt ? mapDate(data.rateUpdatedAt) : undefined,
    youtubeVideoId: data.youtubeVideoId,
    createdAt: mapDate(data.createdAt!),
    statsList: data.statsList.map((stats) => mapPlayerStats(stats)!),
  }
}

function mapPlayerStats(stats: HoopPlayerCard.PlayerGameStats.AsObject): PlayerStats {
  return {
    date: stats.date ? mapDate(stats.date) : undefined,
    homeTeamShortName: mapTeams(stats.homeTeamShortName),
    awayTeamShortName: mapTeams(stats.awayTeamShortName),
    starting: stats.starting,
    totalPlayTime: mapTotalPlayTime(stats.totalPlayTime),
    q1PlayTime: stats.q1PlayTime,
    q2PlayTime: stats.q2PlayTime,
    q3PlayTime: stats.q3PlayTime,
    q4PlayTime: stats.q4PlayTime,
    captainEff: stats.captainEff,
    q1Eff: stats.q1Eff,
    q2Eff: stats.q2Eff,
    q3Eff: stats.q3Eff,
    q4Eff: stats.q4Eff,
  }
}

function mapTeams(team: string): TeamsShortName | undefined {
  switch (team) {
    case '横浜':
      return TeamsShortName.YOKOHAMA_B_CORSAIRS
    case '新潟':
      return TeamsShortName.NIIGATA_ALBIREX_BB
    case '富山':
      return TeamsShortName.TOYAMA_GROUSES
    case '滋賀':
      return TeamsShortName.SHIGA_LAKESTARS
    case '秋田':
      return TeamsShortName.AKITA_NORTHERN_HAPPINETS
    case '三遠':
      return TeamsShortName.SAN_EN_NEOPHOENIX
    case '大阪':
      return TeamsShortName.OSAKA_EVESSA
    case '京都':
      return TeamsShortName.KYOTO_HANNARYZ
    case '北海道':
      return TeamsShortName.LEVANGA_HOKKAIDO
    case '琉球':
      return TeamsShortName.RYUKYU_GOLDEN_KINGS
    case '宇都宮':
      return TeamsShortName.UTSUNOMIYA_BRE
    case '川崎':
      return TeamsShortName.KAWASAKI_BRAVE_THUNDERS
    case 'A東京':
      return TeamsShortName.ALVARK_TOKYO
    case '千葉':
      return TeamsShortName.CHIBA_JETS
    case '名古屋D':
      return TeamsShortName.NAGOYA_DIAMOND_DOLPHINS
    case '群馬':
      return TeamsShortName.GUNMA_CRANE_THUNDERS
    case '三河':
      return TeamsShortName.SEAHORSES_MIKAWA
    case '茨城':
      return TeamsShortName.IBARAKI_ROBOTS
    case '広島':
      return TeamsShortName.HIROSHIMA_DRAGONFLIES
    case '信州':
      return TeamsShortName.SHINSHU_BRAVE_WARRIORS
    case '島根':
      return TeamsShortName.SHIMANE_SUSANOO_MAGIC
    case 'SR渋谷':
      return TeamsShortName.SUNROCKERS_SHIBUYA
    case '仙台':
      return TeamsShortName.SENDAI_89ERS
    case 'FE名古屋':
      return TeamsShortName.FIGHTING_EAGLES_NAGOYA
    case '佐賀':
      return TeamsShortName.SAGA_BALLOONERS
    case '長崎':
      return TeamsShortName.NAGASAKI_VELCA
  }
}

function mapTotalPlayTime(totalPlaySeconds: number): string {
  const min = Math.floor(totalPlaySeconds / 60)
  const seconds = min >= 1 ? totalPlaySeconds % 60 : totalPlaySeconds
  const secondsText = seconds >= 10 ? seconds : `0${seconds}`
  return `${min >= 1 ? min : '00'}:${seconds > 0 ? secondsText : '00'}`
}
