import { useCallback } from 'react'
import * as Sentry from '@sentry/nextjs'
import { mapMaintenance } from './parser/maintenance'

export function useMaintenance() {
  const getMaintenanceData = useCallback(() => {
    const defaultMaintenanceData = {
      isMaintenance: false,
      startAt: undefined,
      endAt: undefined,
    }
    const maintenanceEnv = process.env.NEXT_PUBLIC_MAINTENANCE?.replace(/(\\|\n|\s)/g, '')
    if (!maintenanceEnv) return defaultMaintenanceData

    try {
      return mapMaintenance(JSON.parse(maintenanceEnv))
    } catch (error) {
      Sentry.captureException(error)
      return defaultMaintenanceData
    }
  }, [])

  return {
    getMaintenanceData,
  }
}
