import { useCallback, useState } from 'react'
import type { HoopState } from '@/models/hook'
import { BaseHoopError, HoopErrorUnknown } from '@/lib/error'
import { logoutFirebase } from '@/lib/firebase/auth'
import * as Sentry from '@sentry/nextjs'
import { useSiteData } from './use-site-data'

type Result = HoopState<{}, HoopErrorUnknown>

export function useLogout() {
  const [state, setState] = useState<Result>()
  const { resetSiteData } = useSiteData()

  const logout = useCallback(async () => {
    setState({
      isLoading: true,
      result: undefined,
      error: undefined,
    })

    try {
      await resetSiteData()
      await logoutFirebase()

      setState({
        isLoading: false,
        result: {},
        error: undefined,
      })
    } catch (error) {
      Sentry.captureException(error)
      setState({
        isLoading: false,
        result: undefined,
        error: error instanceof BaseHoopError ? error : new HoopErrorUnknown('error: logout'),
      })
    }
  }, [resetSiteData])

  return {
    state,
    logout,
  }
}
