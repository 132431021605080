import styles from './index.module.scss'
import CloseIcon from '@/assets/icons/close.svg'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useOverlayModal } from '@/hooks/use-overlay-modal'
import { CSSTransition } from 'react-transition-group'

export const OverlayModal = () => {
  const nodeRef = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()
  const { options } = useOverlayModal()

  const isOpen = useMemo(() => {
    return options !== undefined
  }, [options])

  const contentsElement = useMemo<JSX.Element | undefined>(() => {
    if (!options) return
    return options.contents
  }, [options])

  const handleClose = useCallback(() => {
    if (!options) return
    if (!options.callback) return
    options.callback()
  }, [options])

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (options) {
      scrollTarget(scrollElement.current)
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [options, scrollTarget, unScrollTarget])

  return (
    <CSSTransition
      in={isOpen}
      timeout={{
        enter: 0,
        exit: 500,
      }}
      unmountOnExit
      classNames={{
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      nodeRef={nodeRef}
    >
      <div className={styles.container} ref={nodeRef}>
        <div className={styles.inner} ref={scrollElement}>
          <button className={styles.close} onClick={handleClose}>
            <CloseIcon width={24} height={24} />
          </button>
          <div className={styles.body}>{contentsElement}</div>
        </div>
      </div>
    </CSSTransition>
  )
}
