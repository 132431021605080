export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
export const GA_4_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_ID

// send page log
export const sendGtagPageview = (path: string) => {
  if (!GA_4_ID) {
    return
  }

  console.info('sendGtagPageview = ', path)

  window.gtag('config', GA_4_ID, {
    page_path: path,
  })
}

export type GAEvent = {
  category: string
  label: string
}

// send click log
export const sendGtagEvent = (action: string, event: GAEvent) => {
  console.info('sendGtagEvent = ', `action: ${action} category: ${event.category} label: ${event.label}`)

  if (!GA_4_ID) {
    return
  }

  window.gtag('event', action, {
    event_category: event.category,
    event_label: event.label,
  })
}

export const setGtagUserId = (userId: string) => {
  if (!GA_4_ID) {
    return
  }

  console.info('setGtagUserId = ', userId)
  window.gtag('set', { user_id: userId })
}
