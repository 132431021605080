/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ListPointLottery, LotteryItemType as LotteryItemTypeModal, PointHistory, PointLotteryDetail, PointTransactionType, DrawLotsResult } from '@/models/point'
import { ListUserPointHistoriesResponse } from '@dena/hoop-proto-client/dist/users_pb'
import { UserPointTransactionTypeMap, UserPointTransactionType } from '@dena/hoop-proto-client/dist/users_entity_pb'
import { DrawLotsResponse, GetLotteryDetailResponse, GetLotteryResultByUserPointHistoryIdResponse, ListAvailableLotteriesResponse } from '@dena/hoop-proto-client/dist/hoop_pb'
import { LotteryItemType, LotteryItemTypeMap } from '@dena/hoop-proto-client/dist/hoop_entity_pb'
import { mapDate } from './date'

export function mapPointHistory(data: ListUserPointHistoriesResponse.PointHistory.AsObject | undefined): PointHistory | undefined {
  if (!data) return

  switch (mapPointTransactionType(data.transactionType)) {
    case PointTransactionType.LOTTERY:
      return {
        id: data.id,
        type: PointTransactionType.LOTTERY,
        title: data.title,
        date: mapDate(data.date!),
        point: data.point,
        lotteryElected: data.lotteryElected,
      }
    case PointTransactionType.GAME:
      return {
        id: data.id,
        type: PointTransactionType.GAME,
        title: data.title,
        date: mapDate(data.date!),
        point: data.point,
      }
    case PointTransactionType.DAILY_BONUS:
      return {
        id: data.id,
        type: PointTransactionType.DAILY_BONUS,
        title: data.title,
        date: mapDate(data.date!),
        point: data.point,
      }
  }
}

function mapPointTransactionType(type: UserPointTransactionTypeMap[keyof UserPointTransactionTypeMap]): PointTransactionType | undefined {
  switch (type) {
    case UserPointTransactionType.USER_POINT_TRANSACTION_TYPE_GAME:
      return PointTransactionType.GAME
    case UserPointTransactionType.USER_POINT_TRANSACTION_TYPE_LOTTERY:
      return PointTransactionType.LOTTERY
    case UserPointTransactionType.USER_POINT_TRANSACTION_TYPE_DAILY_BONUS:
    default:
      return PointTransactionType.DAILY_BONUS
  }
}

export function mapListAvailableLotteries(data: ListAvailableLotteriesResponse.Lottery.AsObject | undefined): ListPointLottery | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title,
    subTitle: data.subtitle,
    requiredPoint: data.requiredPoint,
    itemImageUrl: data.imageUrl,
    backgroundImageUrl: data.backgroundImageUrl,
    residualQuantity: data.residualQuantity,
    openedAt: mapDate(data.openedAt!),
    closedAt: mapDate(data.closedAt!),
  }
}

export function mapLotteryDetail(data: GetLotteryDetailResponse.AsObject | undefined): PointLotteryDetail | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title,
    subTitle: data.subtitle,
    description: data.description,
    currentPoint: data.pointsHeld,
    requiredPoint: data.requiredPoint,
    imageUrls: data.imageUrlsList,
    itemLimit: data.itemLimit,
    residualQuantity: data.residualQuantity,
    openedAt: mapDate(data.openedAt!),
    closedAt: mapDate(data.closedAt!),
    itemType: mapItemType(data.itemType)!,
    transactionId: data.transactionId,
  }
}

export function mapDrawLots(data: DrawLotsResponse.AsObject | GetLotteryResultByUserPointHistoryIdResponse.AsObject | undefined): DrawLotsResult | undefined {
  if (!data) return

  return {
    title: data.title,
    subtitle: data.subtitle,
    imageUrl: data.imageUrl,
    itemType: mapItemType(data.itemType)!,
    elected: data.elected,
    wallpapers: data.wallpapersList,
    transactionId: data.transactionId,
  }
}

function mapItemType(type: LotteryItemTypeMap[keyof LotteryItemTypeMap]): LotteryItemTypeModal | undefined {
  switch (type) {
    case LotteryItemType.LOTTERY_ITEM_TYPE_UNIFORM:
      return LotteryItemTypeModal.UNIFORM
    case LotteryItemType.LOTTERY_ITEM_TYPE_GIFTCARD:
      return LotteryItemTypeModal.GIFTCARD
    case LotteryItemType.LOTTERY_ITEM_TYPE_NFT:
      return LotteryItemTypeModal.NFT
    case LotteryItemType.LOTTERY_ITEM_TYPE_WALLPAPER:
      return LotteryItemTypeModal.WALLPAPER
    default:
      return
  }
}
