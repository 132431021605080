import { getFirestoreClient } from '@/lib/firebase/'
import { doc, getDocs, query, collection, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, where, setDoc } from 'firebase/firestore'

type ReadSaleData = {
  id: string
  isAlreadyRead: boolean
}

const converter: FirestoreDataConverter<ReadSaleData> = {
  toFirestore: (model: ReadSaleData): DocumentData => {
    return model
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot): ReadSaleData => {
    const data = snapshot.data()

    return {
      id: data.id,
      isAlreadyRead: data.isAlreadyRead,
    }
  },
}

export async function getAlreadReadSaleIds(uid: string): Promise<string[]> {
  const firestore = getFirestoreClient()

  const collectionRef = collection(firestore, 'versions', 'v1', 'users', uid, 'sales')
  const queryRef = query(collectionRef, where('isAlreadyRead', '==', true)).withConverter(converter)

  const snapshot = await getDocs(queryRef)

  return snapshot.docs.map((doc) => doc.data().id)
}

export async function readEntry(uid: string, saleId: string): Promise<void> {
  const firestore = getFirestoreClient()
  const docRef = doc(firestore, 'versions', 'v1', 'users', uid, 'sales', saleId).withConverter(converter)
  await setDoc(docRef, { id: saleId, isAlreadyRead: true })
}
