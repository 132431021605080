import { EntryDetail } from '@/models/entry'
import { Profile } from '@/models/profile'
import { User } from '@/models/user'
import { atom } from 'recoil'

type SiteData = {
  firebaseUid?: string
  user?: User
  profile?: Profile
  hasWallet: boolean
  answeredEnquete: boolean
  unreadEntires: EntryDetail[]
}

export const siteDataAtom = atom<SiteData>({
  key: 'state/siteData',
  default: {
    hasWallet: false,
    answeredEnquete: false,
    unreadEntires: [],
  },
})
