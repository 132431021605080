import { EnqueteAnswer1 } from '@/models/enquete-answer'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { getFirestoreClient } from '@/lib/firebase/'

export async function sendEnqueteAnswer(uid: string, answers: EnqueteAnswer1): Promise<void> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'enquetes', answers.id)
  await setDoc(query, answers)
}

export async function existsEnqueteAnswer(uid: string, enqueteId: string): Promise<boolean> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'enquetes', enqueteId)
  const snapshot = await getDoc(query)

  return snapshot.exists()
}
