import '@/styles/globals.scss'
import 'swiper/css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { RecoilRoot } from 'recoil'
import { Root } from '@/components/root'
import { Loading } from '@/components/root/loading'
import { ErrorModal } from '@/components/root/error-modal'
import { GlobalNavigation } from '@/components/root/global-navigation'
import { NewsList } from '@/components/root/news-list'
import { OverlayModal } from '@/components/root/overlay-modal'
import { SlideUpModal } from '@/components/root/slide-up-modal'
import { SuccessModal } from '@/components/root/success-modal'
import { GotemModal } from '@/components/root/gotem-modal'
import { SorryModal } from '@/components/root/sorry-modal'
import { UnreadMessageModal } from '@/components/root/unread-message-modal'
import { GoogleAnalytics } from '@/components/google-analytics'
import { EntryResultModal } from '@/components/root/entry-result-modal'
import { HoopMetadata } from '@/models/metadata'

const metadatas = {
  title: 'PICKFIVE（ピックファイブ）- あなたは、どの5人で戦う？',
  description: 'PICKFIVE（ピックファイブ）は、試合で活躍する川崎ブレイブサンダースの選手を予想して楽しむことのできるサービスです。',
  ogImage: `${process.env.NEXT_PUBLIC_HOST}/images/ogp_23-24.png`,
  ogUrl: `${process.env.NEXT_PUBLIC_HOST}`,
}

function MyApp({ Component, pageProps }: AppProps<{ title?: string; meta?: HoopMetadata }>) {
  return (
    <RecoilRoot>
      <Head>
        <title>{pageProps.meta?.title || metadatas.title}</title>
        <meta name="viewport" content="width=375, user-scalable=no, viewport-fit=cover" />
        <meta name="description" content={pageProps.meta?.description || metadatas.description} />
        <meta property="og:url" content={pageProps.meta?.ogUrl || metadatas.ogUrl} />
        <meta property="og:title" content={pageProps.meta?.title || metadatas.title} />
        <meta property="og:description" content={pageProps.meta?.description || metadatas.description} />
        <meta property="og:image" content={pageProps.meta?.ogImage || metadatas.ogImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      </Head>
      <GoogleAnalytics />
      <Root>
        <Component {...pageProps} />
      </Root>
      <GlobalNavigation />
      <ErrorModal />
      <Loading />
      <NewsList />
      <SuccessModal />
      <OverlayModal />
      <GotemModal />
      <SorryModal />
      <UnreadMessageModal />
      <EntryResultModal />
      <SlideUpModal />
    </RecoilRoot>
  )
}
export default MyApp
