/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EntryStatus, Entry } from '@/models/entry'
import { UserSaleEntry as HoopUserSaleEntry } from '@dena/hoop-proto-client/dist/app_pb'
import { mapDate } from '.'

export function mapEntry(data: HoopUserSaleEntry.AsObject | undefined): Entry | undefined {
  if (!data) return

  switch (mapEntryStatus(data.status)) {
    case EntryStatus.WAITING_DRAW_LOTS:
      return {
        saleId: data.saleId,
        entryStatus: EntryStatus.WAITING_DRAW_LOTS,
        enteredAt: mapDate(data.enteredAt!),
      }
    case EntryStatus.RESULT_LOSE:
      return {
        saleId: data.saleId,
        entryStatus: EntryStatus.RESULT_LOSE,
        enteredAt: mapDate(data.enteredAt!),
      }
    case EntryStatus.AWAITING_ARRIVAL:
      return {
        saleId: data.saleId,
        entryStatus: EntryStatus.AWAITING_ARRIVAL,
        enteredAt: mapDate(data.enteredAt!),
        serialNumber: data.serialNumber,
      }
    case EntryStatus.RESULT_WIN:
      return {
        saleId: data.saleId,
        entryStatus: EntryStatus.RESULT_WIN,
        enteredAt: mapDate(data.enteredAt!),
        userPlayerCardId: data.userPlayerCardId,
        serialNumber: data.serialNumber,
      }
    case EntryStatus.SOLD:
      return {
        saleId: data.saleId,
        entryStatus: EntryStatus.SOLD,
        enteredAt: mapDate(data.enteredAt!),
        userPlayerCardId: data.userPlayerCardId,
        serialNumber: data.serialNumber,
      }
  }
}

function mapEntryStatus(status: HoopUserSaleEntry.UserSaleEntryStatusMap[keyof HoopUserSaleEntry.UserSaleEntryStatusMap]): EntryStatus | undefined {
  switch (status) {
    case HoopUserSaleEntry.UserSaleEntryStatus.USER_SALE_ENTRY_STATUS_WAITING_DRAW_LOTS:
      return EntryStatus.WAITING_DRAW_LOTS
    case HoopUserSaleEntry.UserSaleEntryStatus.USER_SALE_ENTRY_STATUS_RESULT_WIN:
      return EntryStatus.RESULT_WIN
    case HoopUserSaleEntry.UserSaleEntryStatus.USER_SALE_ENTRY_STATUS_AWAITING_ARRIVAL:
      return EntryStatus.AWAITING_ARRIVAL
    case HoopUserSaleEntry.UserSaleEntryStatus.USER_SALE_ENTRY_STATUS_RESULT_LOSE:
      return EntryStatus.RESULT_LOSE
    case HoopUserSaleEntry.UserSaleEntryStatus.USER_SALE_ENTRY_STATUS_SOLD:
      return EntryStatus.SOLD
  }
}
