import styles from './sns-account.module.scss'
import TwitterLogo from '@/assets/menu/twitter-logo.svg'
import LineLogo from '@/assets/menu/line-logo.svg'

export const SnsAccount = () => {
  return (
    <ul className={styles.sns}>
      <li className={styles.item_twitter}>
        <a href="https://twitter.com/PICKFIVE1" target="_blank" rel="noreferrer">
          <TwitterLogo width={32} />
          <span>
            PICKFIVE公式を
            <br />
            フォロー
          </span>
        </a>
      </li>
      <li className={styles.item_line}>
        <a href="https://lin.ee/jCGnKyL" target="_blank" rel="noreferrer">
          <LineLogo width={32} />
          <span>
            PICKFIVE公式を
            <br />
            友だち追加
          </span>
        </a>
      </li>
    </ul>
  )
}
