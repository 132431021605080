import { getAlreadReadSaleIds } from '@/lib/firebase/entry'
import { EntryDetail, EntryStatus } from '@/models/entry'
import { listEntryDetails } from './app/list-entry-details'

// 未読購入申込み結果一覧を取得
export async function listUnreadEntryDetails(token: string, uid: string): Promise<EntryDetail[]> {
  // EntryDetail配列を取得(from grpc)
  const { entryDetails } = await listEntryDetails(token, 1, 999) // FIXME: 全てのEntryを取得するlimit（第三引数）を指定しなければならない。

  // 抽選結果が既に出ているEntryDetailにフィルタリング（ステータス = 当選 or 落選）
  const filterdEntryDetails = entryDetails.filter((entryDetails) => entryDetails.entry.entryStatus === EntryStatus.RESULT_WIN || entryDetails.entry.entryStatus === EntryStatus.RESULT_LOSE)

  // 既読フラグが立っている sale id 配列を取得（from firestore）
  const alreadyDisplaySaleIds = await getAlreadReadSaleIds(uid)

  // 未読Entry一覧を作成
  const unDisplayEntryDetails = filterdEntryDetails.filter((entryDetail) => !alreadyDisplaySaleIds.includes(entryDetail.saleCard.id))
  return unDisplayEntryDetails
}
