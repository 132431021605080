import { useRecoilState } from 'recoil'
import { globalNavigationState } from '@/recoil/global-navigation-atom'

export const useGlobalNavigation = () => {
  const [isShowGlobalNavigation, setIsShowGlobalNavigation] = useRecoilState(globalNavigationState)
  const showGlobalNavigation = () => setIsShowGlobalNavigation(true)
  const hideGlobalNavigation = () => setIsShowGlobalNavigation(false)

  return {
    isShowGlobalNavigation,
    showGlobalNavigation,
    hideGlobalNavigation,
  }
}
