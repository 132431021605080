import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { routerHistoryAtom } from '@/recoil/router-history'

// react では route の history が取れないので hook を作成
export function useRouterHistory() {
  const [histories, setHistories] = useRecoilState(routerHistoryAtom)

  const pushHistory = useCallback(
    (path: string) => {
      const limit = 100
      // 最大値超えた時
      if (histories.length >= limit) {
        histories.unshift()
      }

      setHistories([...histories, path])
    },
    [histories, setHistories],
  )

  return {
    pushHistory,
    histories,
  }
}
