import { useCallback, useState } from 'react'
import { HoopState } from '@/models/hook'
import { BaseHoopError, HoopErrorUnknown } from '@/lib/error'
import { readEntry } from '@/lib/firebase/entry'
import * as Sentry from '@sentry/nextjs'
import { useRecoilState, atom } from 'recoil'
import { EntryDetail } from '@/models/entry'

type UpdateUnreadEntriesResult = HoopState<{}, HoopErrorUnknown>

type EntryResultModal = {
  contents: EntryDetail
  callback?: () => void
}

// 購入申込結果モーダルに表示するEntryDetail
const entryResultModalDetailState = atom<EntryResultModal | undefined>({
  key: 'state/entry-result-modal-detail',
  default: undefined,
})

export const useEntryResultModal = () => {
  const [state, setState] = useState<UpdateUnreadEntriesResult>()
  const [options, setOptions] = useRecoilState(entryResultModalDetailState)

  // 各結果を閉じる
  const close = useCallback(async () => {
    setOptions(undefined)
  }, [setOptions])

  // 各結果を開く
  const open = useCallback(
    async (options: EntryResultModal) => {
      if (!options.callback) {
        options.callback = () => {
          close()
        }
      }
      setOptions(options)
    },
    [close, setOptions],
  )

  // 既読フラグを立てる
  const readContents = useCallback(async (uid: string, saleId: string) => {
    setState({
      isLoading: true,
      result: undefined,
      error: undefined,
    })

    try {
      await readEntry(uid, saleId)

      setState({
        isLoading: false,
        result: {},
        error: undefined,
      })
    } catch (error) {
      Sentry.captureException(error)
      setState({
        isLoading: false,
        result: undefined,
        error: error instanceof BaseHoopError ? error : new HoopErrorUnknown('failed update unread-entries'),
      })
    }
  }, [])

  return {
    state,
    options,
    open,
    close,
    readContents,
  }
}
