import { atom, useRecoilState } from 'recoil'
import { useCallback } from 'react'
import { InterFaceModal } from '@/interfaces/modal'

type SorryModalOptions = {
  contents: JSX.Element
  callback?: () => void
}

const sorryModalElement = atom<SorryModalOptions | undefined>({
  key: 'state/sorry-modal-element',
  default: undefined,
})

export const useSorryModal = (): InterFaceModal<SorryModalOptions> => {
  const [options, setOptions] = useRecoilState(sorryModalElement)

  const close = useCallback(() => {
    setOptions(undefined)
  }, [setOptions])

  const open = useCallback(
    (options: SorryModalOptions) => {
      if (!options.callback) {
        options.callback = () => {
          close()
        }
      }
      setOptions(options)
    },
    [close, setOptions],
  )

  return {
    options,
    open,
    close,
  }
}
