/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UserPlayerCard, UserPlayerCardLink } from '@/models/user-player-card'
import { UserPlayerCard as HoopUserPlayerCard, UserPlayerCardInfo as HoopUserPlayerCardInfo } from '@dena/hoop-proto-client/dist/hoop_entity_pb'
import { mapRarity, mapPlayer, mapImage } from '../parser'

export function mapUserPlayerCard(data: HoopUserPlayerCard.AsObject | undefined): UserPlayerCard | undefined {
  if (!data) return

  return {
    id: data.id,
    playerCardId: data.playerCardId,
    title: data.title,
    description: data.desc,
    season: data.season,
    series: data.series,
    tokenType: data.itemTokenType,
    tokenName: data.itemTokenName,
    rarity: mapRarity(data.rarity)!,
    teamId: data.teamId,
    playerId: data.playerId,
    player: mapPlayer(data.player)!,
    thumbnailUrl: mapImage(data.thumbnailUrl!),
    mainImageUrl: mapImage(data.mainImageUrl!),
    mainVideoUrl: data.mainVideoUrl,
    serialNumber: data.serialNumber,
    totalMint: data.totalMint,
  }
}

export function mapUserPlayerCardLink(data: HoopUserPlayerCardInfo.AsObject | undefined): UserPlayerCardLink | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title,
    description: data.desc,
    playerCardId: data.playerCardId,
  }
}
