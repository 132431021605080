import { useRecoilState } from 'recoil'
import { loadingState } from '@/recoil/loading-atom'
import { useCallback } from 'react'

export const useLoading = () => {
  const [isLoading, setLoadingStatus] = useRecoilState(loadingState)
  const loadingStart = useCallback(() => {
    setLoadingStatus(true)
  }, [setLoadingStatus])

  const loadingFinish = useCallback(() => {
    setLoadingStatus(false)
  }, [setLoadingStatus])

  return {
    isLoading,
    loadingFinish,
    loadingStart,
  }
}
