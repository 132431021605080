import styles from './menu-login.module.scss'
import Link from 'next/link'
import router from 'next/router'
import { useCallback } from 'react'
import ExclamationCircle from '@/assets/icons/exclamation-circle-border.svg'
import RightIcon from '@/assets/icons/angle-right.svg'
import { useLogout } from '@/hooks/use-logout'
import { useAnalytics } from '@/hooks/use-analytics'
import { SnsAccount } from '@/components/root/global-navigation/sns-account'
import { Image } from '@/components/commons/image'

export const MenuLogin = () => {
  const { logout } = useLogout()
  const { sendEvent } = useAnalytics()

  const handleClickLogout = useCallback(async () => {
    sendEvent('click', { category: 'global_navi', label: 'logout' })
    await logout()
    router.push('/login')
  }, [logout, sendEvent])

  const handleClickTop = useCallback(() => {
    sendEvent('click', { category: 'global_navi', label: 'top' })
  }, [sendEvent])

  const handleClickGameList = useCallback(() => {
    sendEvent('click', { category: 'global_navi', label: 'games' })
  }, [sendEvent])

  const handleClickStore = useCallback(() => {
    sendEvent('click', { category: 'global_navi', label: 'store' })
  }, [sendEvent])

  const handleClickHowToPlay = useCallback(() => {
    sendEvent('click', { category: 'global_navi', label: 'how_to_play' })
  }, [sendEvent])

  return (
    <>
      <ul className={styles.main_memu}>
        <li className={styles.item}>
          <Link href="/">
            <a onClick={handleClickTop}>
              <span className={styles.number}>01</span>
              <span className={styles.text_en}>TOP</span>
              <span className={styles.text_ja}>トップ</span>
            </a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/games">
            <a onClick={handleClickGameList}>
              <span className={styles.number}>02</span>
              <span className={styles.text_en}>GAME LIST</span>
              <span className={styles.text_ja}>試合一覧</span>
            </a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/store">
            <a onClick={handleClickStore}>
              <span className={styles.number}>03</span>
              <span className={styles.text_en}>STORE</span>
              <span className={styles.text_ja}>ストア</span>
            </a>
          </Link>
        </li>
        <li className={styles.banner}>
          <Link href="/how-to-play">
            <a className={styles.inner} onClick={handleClickHowToPlay}>
              <ExclamationCircle width={20} height={20} />
              <p className={styles.text}>
                <span className={styles.text_en}>HOW TO PLAY</span>
                <br />
                <span className={styles.text_ja}>PICKFIVEの遊び方</span>
              </p>
              <RightIcon width={24} height={24} />
              <div className={styles.banner__background}>
                <Image src={require('@/assets/menu/bg-banner.png')} webp={require('@/assets/menu/bg-banner.png?webp')} width={327} height={76} alt={''} />
              </div>
            </a>
          </Link>
        </li>
      </ul>
      <ul className={styles.sub_menu}>
        <li className={styles.item}>
          <a href="https://kawasaki-bravethunders.com/" target="_blank" rel="noopener noreferrer">
            川崎ブレイブサンダース
          </a>
        </li>
        <li className={styles.item}>
          <Link href="/how-to-play#faq">
            <a>よくあるご質問</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/agreement">
            <a>重要な説明事項</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/contact">
            <a>お問い合わせ</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/tokushoho">
            <a>特定商取引法に基づく表記</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/terms">
            <a>ユーザ規約</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/privacy-policy">
            <a>プライバシーポリシー</a>
          </Link>
        </li>
        <li className={styles.item}>
          <a onClick={handleClickLogout}>ログアウト</a>
        </li>
      </ul>
      <SnsAccount />
    </>
  )
}
