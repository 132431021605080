import styles from './index.module.scss'
import { CSSTransition } from 'react-transition-group'
import { useRef } from 'react'
import DrawDownIcon from '@/assets/icons/games/deck/draw-down.svg'

type Props = {
  onClose: () => void
  contents?: JSX.Element
}

export const SlideUpModalInner = ({ onClose, contents }: Props) => {
  const rootElement = useRef<HTMLDivElement>(null)

  return (
    <CSSTransition
      in={!!contents}
      timeout={200}
      unmountOnExit
      classNames={{
        enter: styles.rootEnter,
        enterActive: styles.rootEnterActive,
        exit: styles.rootExit,
        exitActive: styles.rootExitActive,
      }}
      nodeRef={rootElement}
    >
      <div className={styles.root} ref={rootElement}>
        <div className={styles.icon}>
          <div className="svg" onClick={onClose}>
            <DrawDownIcon width={45} height={18} />
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.contents}>
            <div className={styles.scrollArea}>{contents}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
