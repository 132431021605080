import { doc, FirestoreDataConverter, QueryDocumentSnapshot, DocumentData, setDoc, getDoc } from 'firebase/firestore'
import { getFirestoreClient } from '@/lib/firebase/'

type NewsStatusData = {
  lastDisplayNewsId?: string
}

const converter: FirestoreDataConverter<NewsStatusData> = {
  toFirestore: (model: NewsStatusData): DocumentData => {
    return model
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot): NewsStatusData => {
    const data = snapshot.data()

    return {
      lastDisplayNewsId: data.lastDisplayNewsId,
    }
  },
}

export async function setLastDisplayNewsId(uid: string, newsId: string): Promise<void> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private')
  await setDoc(query, { lastDisplayNewsId: newsId }, { merge: true })
}

export async function getLastDisplayNewsId(uid: string): Promise<string | undefined> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private').withConverter(converter)
  const snapshot = await getDoc(query)
  const data = snapshot.data()

  return data?.lastDisplayNewsId
}
