import { useCallback, useState } from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export function useScrollLock() {
  // NOTE: body-scroll-lockのv4.0.0betaには、iOS Safariにおいて「scroll固定時にページtopへ行ってしまうバグ」が存在する
  // NOTE: 解決策として、固定前に一時的にscrollYを保持しておき、固定解除後に再度スクロール量を適応してあげる
  const [temporaryScrollY, setTemporaryScrollY] = useState(0)

  const scrollTarget = useCallback(
    (element: HTMLElement) => {
      const storedScrollY = window.scrollY
      setTemporaryScrollY(storedScrollY)

      disableBodyScroll(element, {
        // NOTE: scrolllockした要素の子孫の中で、特定の要素だけはl移動を許可したい場合
        allowTouchMove: (el) => {
          // NOTE: 以下の2行は input type=[range] の移動を許可している
          if (Object.prototype.toString.call(el) !== '[object HTMLInputElement]') return false
          if ((el as HTMLInputElement).type !== 'range') return false
          return true
        },
      })
      document.body.style.setProperty('top', `${window.scrollY * -1}px`)
    },
    [setTemporaryScrollY],
  )

  const unScrollTarget = useCallback(
    (element: HTMLElement) => {
      enableBodyScroll(element)

      document.body.style.setProperty('top', '')
      document.body.scrollTo(0, temporaryScrollY)
    },
    [temporaryScrollY],
  )

  const clearScrollLock = useCallback(() => {
    clearAllBodyScrollLocks()
  }, [])

  return {
    scrollTarget,
    unScrollTarget,
    clearScrollLock,
  }
}
