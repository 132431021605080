import styles from './index.module.scss'
import { useLoading } from '@/hooks/use-loading'
import lottie from 'lottie-web'
import animationJson from './loading.json'
import { useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'

export const Loading = () => {
  const { isLoading } = useLoading()
  const animationContainer = useRef<HTMLDivElement>(null)
  const nodeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!animationContainer.current) return
    if (!isLoading) {
      return
    }

    lottie.loadAnimation({
      container: animationContainer.current,
      loop: true,
      autoplay: true,
      animationData: animationJson,
    })
  }, [isLoading])

  return (
    <CSSTransition in={isLoading} timeout={200} unmountOnExit={true} classNames="hoop-fade" nodeRef={nodeRef}>
      <div className={styles.loading} ref={nodeRef}>
        <div className="inner">
          <div ref={animationContainer} className={styles.animation} />
          <p className={styles.title}>NOW LOADING</p>
          <p className={styles.description}>
            ※本画面から切り替わらない場合は、
            <br />
            ブラウザで開き直してください
          </p>
        </div>
      </div>
    </CSSTransition>
  )
}
