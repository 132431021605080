import { grpc } from '@improbable-eng/grpc-web'
import { Users } from '@dena/hoop-proto-client/dist/users_pb_service'
import { GetUserRequest, GetUserResponse } from '@dena/hoop-proto-client/dist/users_pb'
import { HoopErrorNotFound, HoopErrorUnknown } from '@/lib/error'
import { User } from '@/models/user'
import { mapUser } from '../parser'

export default function getUser(token: string, id?: string, userName?: string): Promise<User> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }
    if (!id && !userName) {
      reject(new HoopErrorUnknown('need param'))
    }
    const request = new GetUserRequest()
    if (id) {
      request.setId(id)
    }
    if (userName) {
      request.setUserName(userName)
    }

    grpc.unary(Users.GetUser, {
      request,
      host,
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as GetUserResponse.AsObject
          console.info('GetUserRequest data = ', data)

          // TODO: response が正常なときは必ず user が含まれるように proto 変更した方が良さそう
          if (!data.user) {
            reject(new HoopErrorNotFound('user is not found'))
            return
          }

          try {
            const user = mapUser(data.user)
            resolve(user)
          } catch (error) {
            let message = 'failed map user data'
            if (error instanceof Error) {
              message = error.message
            }
            reject(new HoopErrorUnknown(message))
          }
        } else {
          if (status === grpc.Code.NotFound) {
            reject(new HoopErrorNotFound('user is not found'))
          } else {
            reject(new HoopErrorUnknown(`failed GetUserRequest: status = ${status}`))
          }
        }
      },
    })
  })
}
