import { useCallback } from 'react'
import { useRecoilState, atom } from 'recoil'
import { InterFaceModal } from '@/interfaces/modal'

type SlideUpModalOptions = {
  contents: JSX.Element
  callback?: () => void
}

const slideUpModalState = atom<SlideUpModalOptions | undefined>({
  key: 'state/modalData',
  default: undefined,
})

export const useSlideUpModal = (): InterFaceModal<SlideUpModalOptions> => {
  const [options, setOptions] = useRecoilState(slideUpModalState)

  const close = useCallback(() => {
    setOptions(undefined)
  }, [setOptions])

  const open = useCallback(
    (options: SlideUpModalOptions) => {
      if (!options.callback) {
        options.callback = () => {
          close()
        }
      }
      setOptions(options)
    },
    [close, setOptions],
  )

  return {
    options,
    open,
    close,
  }
}
