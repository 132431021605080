import styles from './index.module.scss'
import LoseText from '@/assets/result-modal/lose-text.svg'
import { Image } from '@/components/commons/image'
import { SaleCard } from '@/models/sale'
import { ButtonOutline } from '@/components/commons/button-outline'
import { useCallback } from 'react'
import router from 'next/router'
import { ButtonType } from '@/models/button'

type Props = {
  saleCard: SaleCard
  close: () => void
}

// 「落選」モーダル
export const EntryResultContentsLose = ({ saleCard, close }: Props) => {
  const handleClick = useCallback(() => {
    router.push(`/store/sale/${saleCard.id}`)
    close()
  }, [close, saleCard.id])

  return (
    <div className={styles.contents}>
      <LoseText width={265} height={38} />
      <p className={styles.head}>落選しました</p>
      <p className={styles.description}>
        購入申込みいただきありがとうございました。
        <br />
        発行上限数を上回る申込がございましたので抽選を実施いたしました。厳正な抽選の結果、残念ながらご購入いただくことはできませんでした。
      </p>
      <div className={styles.card_image}>
        <Image src={saleCard.card.mainImage} alt={saleCard.card.title} width={180} height={280} />
      </div>
      <p className={styles.title}>{saleCard.card.title}</p>
      <ButtonOutline isActive={true} onClick={handleClick} type={ButtonType.BUTTON}>
        販売NFTカード詳細
      </ButtonOutline>
    </div>
  )
}
