import { useRecoilState } from 'recoil'
import { profileMenuState } from '@/recoil/profile-menu-atom'

export const useProfileMenu = () => {
  const [isShowProfileMenu, setIsShowProfileMenu] = useRecoilState(profileMenuState)
  const showProfileMenu = () => setIsShowProfileMenu(true)
  const hideProfileMenu = () => setIsShowProfileMenu(false)

  return {
    isShowProfileMenu,
    showProfileMenu,
    hideProfileMenu,
  }
}
