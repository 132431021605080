import { grpc } from '@improbable-eng/grpc-web'
import { Users } from '@dena/hoop-proto-client/dist/users_pb_service'
import { CreateSessionRequest, CreateSessionResponse } from '@dena/hoop-proto-client/dist/users_pb'
import { HoopErrorUnknown } from '@/lib/error'

export default function createSession(token: string | undefined): Promise<CreateSessionResponse.AsObject> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    let metadata
    if (token) {
      metadata = { authorization: `bearer ${token}` }
    }

    const request = new CreateSessionRequest()

    grpc.unary(Users.CreateSession, {
      request,
      host,
      metadata,
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          resolve(message.toObject() as CreateSessionResponse.AsObject)
        } else {
          reject(status)
        }
      },
    })
  })
}
