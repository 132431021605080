import styles from './index.module.scss'
import classNames from 'classnames'
import { ButtonProps, LinkProps, ButtonType } from '@/models/button'
import LoadingIcon from '@/assets/icons/loading.svg'
import Link from 'next/link'
import { useMemo } from 'react'

type Props = ButtonProps | LinkProps

export const ButtonOutline = (props: Props) => {
  const buttonIcon = useMemo<JSX.Element | undefined>(() => {
    if (props.type === ButtonType.LINK) return
    if (props.isLoading) return <LoadingIcon className={styles.loadingIcon} width={24} height={24} />
  }, [props])

  const buttonElement = useMemo<JSX.Element>(() => {
    switch (props.type) {
      case ButtonType.BUTTON:
        return (
          <button className={classNames(styles.buttonOutline, { [styles.loading]: props.isLoading, [styles.disabled]: !props.isActive })} disabled={!props.isActive} onClick={() => props.onClick()}>
            {props.children}
            {buttonIcon}
          </button>
        )
      case ButtonType.LINK:
        return (
          <div className={styles.buttonOutline}>
            <Link href={props.href}>
              <a>
                {props.children}
                {buttonIcon}
              </a>
            </Link>
          </div>
        )
    }
  }, [buttonIcon, props])

  return buttonElement
}
