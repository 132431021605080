import { useCallback, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'
// import { siteDataAtom } from '@/recoil/site-data'
import { getFirebaseLoginToken, getFirebaseLoginUid } from '@/lib/firebase/auth'
import getUserRequest from '@/lib/grpc/user/get-user'
import createSession from '@/lib/grpc/user/create-session'
import { listNews } from '@/lib/grpc/list-news'
import hasWalletAddress from '@/lib/grpc/blockchain/has-wallet-address'
import { GeneralNews, UserNews } from '@/models/news'
import { User } from '@/models/user'
import { listUnreadEntryDetails } from '@/lib/grpc/list-unread-entry-details'
import { newsDataAtom, NewsStatusDataAtom } from '@/recoil/news'
import { existsEnqueteAnswer } from '@/lib/firebase/enquete'
import { isDisplayedUserIconBadgeAtom } from '@/recoil/user-icon-badge-atom'
import { isDisplayedProfileNewIconAtom } from '@/recoil/profile-new-icon-atom'
import { getLastDisplayNewsId as getFirestoreLastDisplayNewsId, setLastDisplayNewsId as setFirestoreLastDisplayNewsId } from '@/lib/firebase/last-news-id'
import { getDisplayUserIconBadgeStatus as getFirestoreDisplayUserIconBadgeStatus, setDisplayUserIconBadgeStatus as setFirestoreDisplayUserIconBadgeStatus } from '@/lib/firebase/display-user-icon-badge'
import { getDisplayedProfileNewIconStatus as getFirestoreDisplayedProfileNewIconStatus, setDisplayedProfileNewIconStatus as setFirestoreDisplayedProfileNewIconStatus } from '@/lib/firebase/display-profile-new-icon'
import { HoopErrorNotFound, HoopErrorUnknown } from '@/lib/error'
import * as Sentry from '@sentry/nextjs'
import { Profile } from '@/models/profile'
import { getPublicProfile } from '@/lib/firebase/profile'
import { EntryDetail } from '@/models/entry'
import { useMaintenance } from './maintenance'

const firebaseUidState = atom<string | undefined>({
  key: 'state/firebaseUid',
  default: undefined,
})

const userState = atom<User | undefined>({
  key: 'state/user',
  default: undefined,
})

const profileState = atom<Profile | undefined>({
  key: 'state/profile',
  default: undefined,
})

const hasWalletState = atom<boolean>({
  key: 'state/hasWallet',
  default: false,
})

const isAnsweredEnquete1State = atom<boolean>({
  key: 'state/isAnsweredEnquete1',
  default: false,
})

const unreadEntiresState = atom<EntryDetail[]>({
  key: 'state/unreadEntires',
  default: [],
})

export function useSiteData() {
  // const [siteData, setSiteData] = useRecoilState(siteDataAtom)
  const [firebaseUid, setFirebaseUid] = useRecoilState(firebaseUidState)
  const [user, setUser] = useRecoilState(userState)
  const [profile, setProfile] = useRecoilState(profileState)
  const [hasWallet, setHasWallet] = useRecoilState(hasWalletState)
  const [isAnsweredEnquete1, setIsAnsweredEnquete1] = useRecoilState(isAnsweredEnquete1State)
  const [unreadEntires, setUnreadEntires] = useRecoilState(unreadEntiresState)
  const { getMaintenanceData } = useMaintenance()

  const [news, setNews] = useRecoilState(newsDataAtom)
  const [statusNewsData, setStatusNewsData] = useRecoilState(NewsStatusDataAtom)
  const [isDisplayedUserIconBadge, setIsDisplayedUserIconBadge] = useRecoilState(isDisplayedUserIconBadgeAtom)
  const [isDisplayedProfileNewIcon, setIsDisplayedProfileNewIcon] = useRecoilState(isDisplayedProfileNewIconAtom)

  const isMaintenance = useMemo(() => {
    const maintenance = getMaintenanceData()
    return maintenance.isMaintenance
  }, [getMaintenanceData])

  const isLogin = useMemo<boolean>(() => {
    return firebaseUid !== undefined
  }, [firebaseUid])

  const isCreateUser = useMemo<boolean>(() => {
    return user !== undefined
  }, [user])

  const isSetBirthday = useMemo<boolean>(() => {
    return user?.birthday !== undefined
  }, [user?.birthday])

  const hasUnreadNews = useMemo<boolean>(() => {
    if (!news.news.length) return false
    return news.news[0].id !== statusNewsData.lastDisplayNewsId
  }, [news.news, statusNewsData])

  const setUserData = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    const token = await getFirebaseLoginToken()

    if (!token || !firebaseUid) {
      setUser(undefined)
      return
    }

    try {
      const user = await getUserRequest(token, firebaseUid)
      setUser(user)
    } catch (error) {
      const isUserNotFound = error instanceof HoopErrorNotFound
      // user が「見つからない」以外はエラー
      if (!isUserNotFound) {
        throw error
      }
    }
  }, [setUser])

  const setHasWalletData = useCallback(async () => {
    const token = await getFirebaseLoginToken()
    if (!token) {
      setHasWallet(false)
      return
    }

    const hasWallet = await hasWalletAddress(token)
    setHasWallet(hasWallet)
  }, [setHasWallet])

  const setProfileData = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    if (!firebaseUid) {
      setProfile(undefined)
      return
    }

    const profile = await getPublicProfile(firebaseUid)
    setProfile(profile)
  }, [setProfile])

  const setAnsweredEnquete1Data = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    if (!firebaseUid) {
      return
    }

    const isAnsweredEnquete1 = await existsEnqueteAnswer(firebaseUid, 'enquete1')
    setIsAnsweredEnquete1(isAnsweredEnquete1)
  }, [setIsAnsweredEnquete1])

  const setUnreadEntiresData = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    const token = await getFirebaseLoginToken()
    if (!firebaseUid || !token) {
      return
    }

    const unreadEntires = await listUnreadEntryDetails(token, firebaseUid)
    setUnreadEntires(unreadEntires)
  }, [setUnreadEntires])

  const initSiteData = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    setFirebaseUid(firebaseUid)

    const token = await getFirebaseLoginToken()
    await createSession(token)

    await setUserData()
    await setProfileData()
    await setHasWalletData()
    await setAnsweredEnquete1Data()
    await setUnreadEntiresData()
  }, [setAnsweredEnquete1Data, setFirebaseUid, setHasWalletData, setProfileData, setUnreadEntiresData, setUserData])

  // MEMO: updateSiteData を無くして、明示的に update したい data を update したい compnent から指定する
  const updateSiteData = useCallback(async () => {
    await initSiteData()
  }, [initSiteData])

  const resetSiteData = useCallback(async () => {
    setFirebaseUid(undefined)
    setUser(undefined)
    setProfile(undefined)
    setHasWallet(false)
    setIsAnsweredEnquete1(false)
    setUnreadEntires([])
  }, [setFirebaseUid, setHasWallet, setIsAnsweredEnquete1, setProfile, setUnreadEntires, setUser])

  const updateNews = useCallback(async () => {
    let generalNews: GeneralNews[] = []
    let userNews: UserNews[] = []

    const firebaseUid = await getFirebaseLoginUid()
    const token = await getFirebaseLoginToken()

    if (token && firebaseUid) {
      const news = await listNews(token, firebaseUid)
      generalNews = news.generalNews
      userNews = news.userNews
    }

    const news = [...generalNews, ...userNews].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))

    setNews({
      news,
    })
  }, [setNews])

  const updateLastDisplayNewsId = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    let lastDisplayNewsId: string | undefined

    if (firebaseUid) {
      lastDisplayNewsId = await getFirestoreLastDisplayNewsId(firebaseUid)
    }

    setStatusNewsData({
      lastDisplayNewsId,
    })
  }, [setStatusNewsData])

  const setLastDisplayNewsId = useCallback(
    async (newsId: string) => {
      try {
        const firebaseUid = await getFirebaseLoginUid()
        if (!firebaseUid) {
          throw new HoopErrorUnknown('cannot get firebase uid')
        }

        await setFirestoreLastDisplayNewsId(firebaseUid, newsId)

        setStatusNewsData({
          lastDisplayNewsId: newsId,
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [setStatusNewsData],
  )

  const getDisplayUserIconBadgeStatus = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    let isDisplayedUserIconBadge: boolean | undefined

    if (firebaseUid) {
      isDisplayedUserIconBadge = await getFirestoreDisplayUserIconBadgeStatus(firebaseUid)
    }

    setIsDisplayedUserIconBadge(isDisplayedUserIconBadge)
  }, [setIsDisplayedUserIconBadge])

  const setDisplayUserIconBadgeStatus = useCallback(async () => {
    try {
      const firebaseUid = await getFirebaseLoginUid()
      if (!firebaseUid) {
        throw new HoopErrorUnknown('cannot get firebase uid')
      }

      await setFirestoreDisplayUserIconBadgeStatus(firebaseUid)

      setIsDisplayedUserIconBadge(true)
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [setIsDisplayedUserIconBadge])

  const getDisplayProfileNewIconStatus = useCallback(async () => {
    const firebaseUid = await getFirebaseLoginUid()
    let isDisplayedProfileNewIcon: boolean | undefined

    if (firebaseUid) {
      isDisplayedProfileNewIcon = await getFirestoreDisplayedProfileNewIconStatus(firebaseUid)
    }

    setIsDisplayedProfileNewIcon(isDisplayedProfileNewIcon)
  }, [setIsDisplayedProfileNewIcon])

  const setDisplayProfileNewIconStatus = useCallback(async () => {
    try {
      const firebaseUid = await getFirebaseLoginUid()
      if (!firebaseUid) {
        throw new HoopErrorUnknown('cannot get firebase uid')
      }

      await setFirestoreDisplayedProfileNewIconStatus(firebaseUid)

      setIsDisplayedProfileNewIcon(true)
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [setIsDisplayedProfileNewIcon])

  // const updateUser = useCallback<() => Promise<void>>(async () => {
  //   try {
  //     const firebaseUid = await getFirebaseLoginUid()
  //     const token = await getFirebaseLoginToken()

  //     if (!token) {
  //       throw new HoopErrorUnknown('cannot get token')
  //     }
  //     if (!firebaseUid) {
  //       throw new HoopErrorUnknown('cannot get firebase uid')
  //     }
  //     const user = await getUserRequest(token, firebaseUid)
  //     setSiteData((siteData) => ({ ...siteData, user: user }))
  //   } catch (error) {
  //     Sentry.captureException(error)
  //   }
  // }, [setSiteData])

  return {
    isLogin,
    isCreateUser,
    isSetBirthday,
    answeredEnquete: isAnsweredEnquete1,
    hasWallet,
    firebaseUid: firebaseUid,
    user: user,
    profile: profile,
    hasUnreadNews,
    news: news.news,
    unreadEntires: unreadEntires,
    isDisplayedUserIconBadge,
    isDisplayedProfileNewIcon,
    isMaintenance,
    initSiteData,
    updateSiteData,
    updateNews,
    updateLastDisplayNewsId,
    setLastDisplayNewsId,
    getDisplayUserIconBadgeStatus,
    setDisplayUserIconBadgeStatus,
    getDisplayProfileNewIconStatus,
    setDisplayProfileNewIconStatus,
    // updateUser,
    setUserData,
    resetSiteData,
    setAnsweredEnquete1Data,
    setUnreadEntiresData,
    setProfileData,
    setHasWalletData,
  }
}
