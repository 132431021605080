import { InterFaceModal } from '@/interfaces/modal'
import { useCallback } from 'react'
import { useRecoilState, atom } from 'recoil'

type OverlayModalOptions = {
  contents: JSX.Element
  callback?: () => void
}

const overlayModalState = atom<OverlayModalOptions | undefined>({
  key: 'state/overlay-modal',
  default: undefined,
})

export const useOverlayModal = (): InterFaceModal<OverlayModalOptions> => {
  const [options, setOptions] = useRecoilState(overlayModalState)

  const close = useCallback(() => {
    setOptions(undefined)
  }, [setOptions])

  const open = useCallback(
    (options: OverlayModalOptions) => {
      if (!options.callback) {
        options.callback = () => {
          close()
        }
      }
      setOptions(options)
    },
    [close, setOptions],
  )

  return {
    options,
    open,
    close,
  }
}
