import { useState, useCallback, useEffect } from 'react'
import router from 'next/router'
import { HoopState } from '@/models/hook'
import { initFirebase } from '@/lib/firebase'
import { BaseHoopError, HoopErrorUnknown } from '@/lib/error'
import { useSiteData } from '@/hooks/use-site-data'
import { useLoading } from '@/hooks/use-loading'
import { useGlobalNavigation } from '@/hooks/use-global-navigation'
import { useNewsList } from '@/hooks/use-news-list'
import { useRecoilState } from 'recoil'
import { siteDataAtom } from '@/recoil/site-data'
import { useProfileMenu } from '@/hooks/use-profile-menu'
import { useOverlayModal } from '@/hooks/use-overlay-modal'
import { useGotemModal } from '@/hooks/use-gotem-modal'
import { useSorryModal } from '@/hooks/use-sorry-modal'
import { useRouterHistory } from '../use-router-history'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import initGrpc from '@/lib/grpc'
import * as Sentry from '@sentry/nextjs'
import { useMaintenance } from '@/hooks/maintenance'

type Result = HoopState<{}, HoopErrorUnknown>

// option
type HandlerOptions = {
  shallow: boolean
}

export function useRoot() {
  const [state, setState] = useState<Result>()
  const { initSiteData, updateNews, updateLastDisplayNewsId, getDisplayUserIconBadgeStatus, getDisplayProfileNewIconStatus } = useSiteData()
  const { getMaintenanceData } = useMaintenance()
  const { hideGlobalNavigation } = useGlobalNavigation()
  const { hideNewsList } = useNewsList()
  const { hideProfileMenu } = useProfileMenu()
  const { close: closeOverlayModal } = useOverlayModal()
  const { close: closeGotemModal } = useGotemModal()
  const { close: closeSorryModal } = useSorryModal()
  const { loadingStart } = useLoading()
  const [siteData] = useRecoilState(siteDataAtom)
  const { pushHistory } = useRouterHistory()
  const { clearScrollLock } = useScrollLock()

  const onChangeRoute = useCallback(
    (path: string, options: HandlerOptions) => {
      // shallow な遷移は無視 (albumのquery遷移など)
      if (options.shallow) {
        return
      }

      if (path === router.asPath) {
        return
      }

      loadingStart()
      hideGlobalNavigation()
      hideNewsList()
      hideProfileMenu()
      closeOverlayModal()
      closeGotemModal()
      closeSorryModal()
      pushHistory(router.asPath)
      clearScrollLock()
    },
    [closeGotemModal, closeSorryModal, closeOverlayModal, hideGlobalNavigation, hideNewsList, hideProfileMenu, loadingStart, pushHistory, clearScrollLock],
  )

  useEffect(() => {
    router.events.on('routeChangeStart', onChangeRoute)

    return () => {
      router.events.off('routeChangeStart', onChangeRoute)
    }
  }, [onChangeRoute])

  const getSiteData = useCallback(async (): Promise<void> => {
    try {
      initFirebase()
      initGrpc()

      const maintenance = getMaintenanceData()

      // MEMO: 強制ログアウトのときはupdateSiteDataを実行しない
      if (!maintenance.isMaintenance && router.pathname !== '/logout') {
        await initSiteData()
        await updateNews()
        await updateLastDisplayNewsId()
        await getDisplayUserIconBadgeStatus()
        await getDisplayProfileNewIconStatus()
      }

      setState({
        isLoading: false,
        result: {},
        error: undefined,
      })
    } catch (error) {
      Sentry.captureException(error)
      setState({
        isLoading: false,
        result: undefined,
        error: error instanceof BaseHoopError ? error : new HoopErrorUnknown('failed get site data'),
      })
    }
  }, [])

  useEffect(() => {
    if (siteData) {
      console.info('siteData = ', siteData)
    }
  }, [siteData])

  return {
    state,
    getSiteData,
  }
}
