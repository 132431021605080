/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Maintenance } from '@/models/maintenance'

type MaintenanceData = {
  isMaintenance: boolean
  startAt: string
  endAt: string
}

export function mapMaintenance(data: MaintenanceData): Maintenance {
  return {
    isMaintenance: data.isMaintenance,
    startAt: data.startAt.length ? new Date(data.startAt) : undefined,
    endAt: data.endAt.length ? new Date(data.endAt) : undefined,
  }
}
