/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Player } from '@/models/player'
import { Player as HoopPlayer } from '@dena/hoop-proto-client/dist/hoop_entity_pb'
import { mapDate, mapImage } from '../parser'

export function mapPlayer(data: HoopPlayer.AsObject | undefined): Player | undefined {
  if (!data) return

  return {
    id: data.id,
    bleagueTeamId: data.bleagueTeamId,
    bleaguePlayerId: data.bleaguePlayerId,
    teamId: data.teamId,
    name: data.name,
    nameEnglish: data.nameEnglish,
    uniformNumber: data.uniformNumber,
    position: data.position,
    positionName: data.positionName,
    positionNameShort: data.position,
    positionNameEnglish: data.positionNameEnglish,
    birthday: data.birthday ? mapDate(data.birthday) : undefined,
    birthPlace: data.birthplace,
    height: data.height,
    weight: data.weight,
    thumbnailUrl: mapImage(data.thumbnailUrl!),
    profileImageUrl: mapImage(data.profileImageUrl!),
    officialProfileUrl: data.officialProfileUrl!,
  }
}
