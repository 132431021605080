import styles from './index.module.scss'
import { useMemo, useRef, useEffect, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import Close from '@/assets/icons/close.svg'
import { EntryStatus } from '@/models/entry'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import { EntryResultContentsWin } from '@/components/root/entry-result-modal/win'
import { EntryResultContentsLose } from '@/components/root/entry-result-modal/lose'
import { EntryResultContentsSold } from '@/components/root/entry-result-modal/sold'
import { useEntryResultModal } from '@/hooks/use-entry-result-modal'

export const EntryResultModal = () => {
  const nodeRef = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()
  const { options } = useEntryResultModal()

  const isOpen = useMemo(() => {
    return options !== undefined
  }, [options])

  const handleClose = useCallback(() => {
    if (!options) return
    if (!options.callback) return
    options.callback()
  }, [options])

  const modalContentElement = useMemo(() => {
    if (!options) return
    switch (options.contents.entry.entryStatus) {
      case EntryStatus.SOLD:
        return <EntryResultContentsSold saleCard={options.contents.saleCard} entry={options.contents.entry} close={handleClose} />
      case EntryStatus.RESULT_WIN:
        return <EntryResultContentsWin saleCard={options.contents.saleCard} entry={options.contents.entry} close={handleClose} />
      case EntryStatus.RESULT_LOSE:
        return <EntryResultContentsLose saleCard={options.contents.saleCard} close={handleClose} />
    }
  }, [handleClose, options])

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (isOpen) {
      scrollTarget(scrollElement.current)
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [isOpen, scrollTarget, unScrollTarget])

  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={{
          enter: 0,
          exit: 500,
        }}
        unmountOnExit
        classNames={{
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone,
        }}
        nodeRef={nodeRef}
      >
        <div className={styles.modal} ref={nodeRef}>
          <div className={styles.inner} ref={scrollElement}>
            <button className={styles.button_close} onClick={handleClose}>
              <Close width={24} height={24} />
            </button>
            {modalContentElement}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
