/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { grpc } from '@improbable-eng/grpc-web'
import { App } from '@dena/hoop-proto-client/dist/app_pb_service'
import { ListUserSaleEntriesRequest, ListUserSaleEntriesResponse } from '@dena/hoop-proto-client/dist/app_pb'
import { HoopErrorUnknown } from '@/lib/error'
import { mapEntry, mapSaleCard } from '../parser'
import { EntryDetail } from '@/models/entry'

type Result = {
  entryDetails: EntryDetail[]
  totalPage: number
}

export async function listEntryDetails(token: string, page: number, limit: number): Promise<Result> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new ListUserSaleEntriesRequest()

    request.setPageNumber(page)
    request.setPageItemLimit(limit)

    grpc.unary(App.ListUserSaleEntries, {
      request,
      host,
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response

        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as ListUserSaleEntriesResponse.AsObject
          console.info('ListUserSaleEntries data = ', data)

          resolve({
            entryDetails: data.entriesList.map((item) => {
              return {
                saleCard: mapSaleCard(item.sale)!,
                entry: mapEntry(item.userEntry)!,
              }
            }),
            totalPage: data.maxPageNumber,
          })
        } else {
          reject(new HoopErrorUnknown(`failed ListUserSaleEntries status = ${status}`))
        }
      },
    })
  })
}
