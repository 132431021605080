// ポイント履歴項目（くじ）
export interface PointHistoryByLottery {
  id: string
  type: 'ポイントくじ'
  title: string
  date: Date
  point: number
  lotteryElected: boolean
}

// ポイント履歴項目（くじ無し）
export interface PointHistoryNoLottery {
  id: string
  type: '試合予想' | 'デイリーボーナス'
  title: string
  date: Date
  point: number
}

export type PointHistory = PointHistoryByLottery | PointHistoryNoLottery

// ポイント増減の経緯
export const PointTransactionType = {
  LOTTERY: 'ポイントくじ',
  GAME: '試合予想',
  DAILY_BONUS: 'デイリーボーナス',
} as const
export type PointTransactionType = typeof PointTransactionType[keyof typeof PointTransactionType]

// ポイントくじ一覧
export interface ListPointLottery {
  id: string
  title: string
  subTitle: string
  requiredPoint: number // 必要ポイント
  itemImageUrl: string
  backgroundImageUrl: string
  residualQuantity: number // 賞品在庫
  openedAt: Date
  closedAt: Date
}

// ポイントくじ詳細
export interface PointLotteryDetail {
  id: string
  title: string
  subTitle: string
  description: string
  imageUrls: string[]
  currentPoint: number // 保有ポイント
  requiredPoint: number // 必要ポイント
  itemLimit: number // 賞品総数
  residualQuantity: number // 賞品在庫
  openedAt: Date
  closedAt: Date
  itemType: LotteryItemType
  transactionId: string
}

// ポイントくじ抽選結果
export interface DrawLotsResult {
  title: string
  subtitle: string
  imageUrl: string
  itemType: LotteryItemType
  elected: boolean // 当落
  wallpapers: Wallpaper[]
  transactionId: string
}

// ポイントくじ賞品タイプ
export const LotteryItemType = {
  UNIFORM: 'UNIFORM',
  GIFTCARD: 'GIFTCARD',
  NFT: 'NFT',
  WALLPAPER: 'WALLPAPER',
} as const
export type LotteryItemType = typeof LotteryItemType[keyof typeof LotteryItemType]

export interface Wallpaper {
  url: string
  caption: string
}
