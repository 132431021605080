import { doc, getDoc, getDocs, query as firestoreQuery, collection, orderBy, limit, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { UserNews } from '@/models/news'
import { getFirestoreClient } from '@/lib/firebase/'

const converter: FirestoreDataConverter<UserNews> = {
  toFirestore: (model: UserNews): DocumentData => {
    return model
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot): UserNews => {
    const data = snapshot.data()

    return {
      id: data.id,
      title: data.title.replace(/\\\\n/g, '\n'),
      body: data.body.replace(/\\\\n/g, '\n'),
      thumbnailUrl: data.thumbnailUrl ? data.thumbnailUrl.size_2x : undefined,
      imageUrl: data.imageUrl ? data.imageUrl.size_2x : undefined,
      url: data.url,
      isAlreadyRead: data.isAlreadyRead,
      createdAt: data.createdAt.toDate(),
    }
  },
}

export async function getNotification(uid: string, id: string): Promise<UserNews | undefined> {
  const firestore = getFirestoreClient()
  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'notifications', id).withConverter(converter)
  const snapshot = await getDoc(query)

  const notifications = snapshot.data()

  return notifications
}

export async function list(uid: string): Promise<UserNews[]> {
  const firestore = getFirestoreClient()
  const query = firestoreQuery(collection(firestore, 'versions', 'v1', 'users', uid, 'notifications'), orderBy('createdAt', 'desc'), limit(100)).withConverter(converter)
  const snapshot = await getDocs(query)

  return snapshot.docs.map((doc) => doc.data())
}
