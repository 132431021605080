import { doc, FirestoreDataConverter, QueryDocumentSnapshot, DocumentData, setDoc, getDoc } from 'firebase/firestore'
import { getFirestoreClient } from '@/lib/firebase/'

type UserIconBudgeData = {
  isDisplayedUserIconBadge?: boolean
}

const converter: FirestoreDataConverter<UserIconBudgeData> = {
  toFirestore: (model: UserIconBudgeData): DocumentData => {
    return model
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot): UserIconBudgeData => {
    const data = snapshot.data()

    return {
      isDisplayedUserIconBadge: data.isDisplayedUserIconBadge,
    }
  },
}

export async function setDisplayUserIconBadgeStatus(uid: string): Promise<void> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private')
  await setDoc(query, { isDisplayedUserIconBadge: true }, { merge: true })
}

export async function getDisplayUserIconBadgeStatus(uid: string): Promise<boolean | undefined> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private').withConverter(converter)
  const snapshot = await getDoc(query)
  const data = snapshot.data()

  return data?.isDisplayedUserIconBadge
}
