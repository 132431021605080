export class BaseHoopError extends Error {}

export class HoopErrorNotFound extends BaseHoopError {}
export class HoopErrorPassedReceptionDeadline extends BaseHoopError {} // デッキ編成受付の締切を過ぎた
export class HoopErrorHasNotDeck extends BaseHoopError {} // デッキ持ってないのに、デッキ必要なページ用(試合中画面とか)
export class HoopErrorDrawLotsStatusNotEnoughpoint extends BaseHoopError {} // ポイントくじ抽選ポイント不足
export class HoopErrorDrawLotsStatusSoldout extends BaseHoopError {} // ポイントくじ抽選賞品在庫なし
export class HoopErrorDrawLotsStatusUnavailable extends BaseHoopError {} // ポイントくじ抽選期間外
export class HoopErrorPromotionCodeInvalid extends BaseHoopError {} // 無効のプロモーションコード
export class HoopErrorPromotionCodeAlreadyUsed extends BaseHoopError {} // 使用済のプロモーションコード
export class HoopErrorDeleteUserStatusNowEntering extends BaseHoopError {} // 購入エントリー中に退会しようとしたとき
export class HoopErrorSetUserProfileStatusInvalid extends BaseHoopError {}
export class HoopErrorSetUserProfileStatusInvalidNickname extends BaseHoopError {} // ニックネーム不正
export class HoopErrorSetUserProfileStatusDuplicateNickname extends BaseHoopError {} // ニックネーム重複
export class HoopErrorSetUserProfileStatusInvalidDescription extends BaseHoopError {} // 説明文が不正
export class HoopErrorSetUserProfileStatusInvalidIconImage extends BaseHoopError {} // アイコン画像が不正
export class HoopErrorSetUserProfileStatusInvalidBackgroundImage extends BaseHoopError {} // 背景画像が不正
export class HoopErrorAlreadyExists extends BaseHoopError {} // 使用済ユーザID or ニックネーム
export class HoopErrorFaildPayment extends BaseHoopError {} // 利用不可のcredit card
export class HoopErrorExceedLimitAgeRestriction extends BaseHoopError {} // 未成年者が制限金額を超えて購入しようとしている
export class HoopErrorOutOfTermEntry extends BaseHoopError {} // 期間外で抽選不可
export class HoopErrorStockSaleSoldout extends BaseHoopError {} // 先着販売が売り切れで購入できない
export class HoopErrorCreateCustomTokenInvalidState extends BaseHoopError {} // firebase login 時にログイン失敗時 (private browser→line appの時、cookieが引き継げなくて失敗する)
export class HoopErrorCreateCustomTokenUnspecified extends BaseHoopError {}
export class HoopErrorRefuseAddCreditCard extends BaseHoopError {} // credit card を拒否した時 (日本以外の発行など)
export class HoopErrorInvalidAddCreditCard extends BaseHoopError {} // credit card の登録失敗時 (3Dセキュアなど)
export class HoopErrorUnknown extends BaseHoopError {}
