import { atom, useRecoilState } from 'recoil'
import { useCallback } from 'react'
import { InterFaceModal } from '@/interfaces/modal'

type ErrorModalOptions = {
  title?: string
  description?: string
  callback?: () => void
}

const errorModalState = atom<ErrorModalOptions | undefined>({
  key: 'state/error-modal',
  default: undefined,
})

export const useErrorModal = (): InterFaceModal<ErrorModalOptions> => {
  const [options, setOptions] = useRecoilState(errorModalState)

  const close = useCallback(() => {
    setOptions(undefined)
  }, [setOptions])

  const open = useCallback(
    (options: ErrorModalOptions) => {
      if (!options.title) {
        options.title = 'エラーが発生しました'
      }

      if (!options.description) {
        options.description = '再度時間をおいてお試し下さい'
      }

      if (!options.callback) {
        options.callback = () => {
          close()
        }
      }

      setOptions(options)
    },
    [close, setOptions],
  )

  return {
    options,
    open,
    close,
  }
}
