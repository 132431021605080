import { useEffect, useMemo, useRef, useCallback } from 'react'
import styles from './index.module.scss'
import EnvelopeIcon from '@/assets/icons/envelope.svg'
import { CSSTransition } from 'react-transition-group'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import { useEntryResultModal } from '@/hooks/use-entry-result-modal'
import { useSiteData } from '@/hooks/use-site-data'
import router from 'next/router'

// 未読通知モーダル
export const UnreadMessageModal = () => {
  const nodeRef = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()
  const { firebaseUid, unreadEntires, user, setUnreadEntiresData } = useSiteData()
  const { open: openContents, readContents } = useEntryResultModal()

  // ログイン済みユーザで、且つHOMEページ・履歴ページ・保有カードページ・自身プロフィールページである場合のみ、未読があることを通知する
  const canOpenResult = useMemo(() => {
    if (!user) return
    const paths = ['/', '/entries', `/cards/user/${user.userName}`, `/user/${user.userName}`]
    return paths.some((item) => item === router.asPath)
  }, [user])

  const isOpen = useMemo(() => {
    if (!canOpenResult) return
    if (unreadEntires === undefined) return
    return unreadEntires.length > 0
  }, [canOpenResult, unreadEntires])

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (isOpen) {
      scrollTarget(scrollElement.current)
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [isOpen, scrollTarget, unScrollTarget])

  const countElement = useMemo(() => {
    if (unreadEntires === undefined) return
    return <div className={styles.count}>（全{unreadEntires.length}件）</div>
  }, [unreadEntires])

  const handleClickCheck = useCallback(async () => {
    if (unreadEntires.length < 1) return
    const newCurrentEntryDetail = unreadEntires.slice(-1)[0]
    openContents({
      contents: newCurrentEntryDetail,
    })
    if (!firebaseUid) return
    await readContents(firebaseUid, newCurrentEntryDetail.saleCard.id)

    await setUnreadEntiresData()
  }, [firebaseUid, unreadEntires])

  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={{
          enter: 0,
          exit: 500,
        }}
        unmountOnExit
        classNames={{
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone,
        }}
        nodeRef={nodeRef}
      >
        <div className={styles.container} ref={nodeRef}>
          <div className={styles.inner} ref={scrollElement}>
            <div className={styles.notification}>
              <EnvelopeIcon width={48} height={48} />
              <div className={styles.title}>購入申込結果のお知らせ</div>
              {countElement}
              <p className={styles.description}>購入申込みいただいたカードの結果が発表されました。ご確認ください。</p>
              <button className={styles.button} onClick={handleClickCheck}>
                確認する
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
