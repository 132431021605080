import styles from './menu-logout.module.scss'
import Link from 'next/link'
import { useGlobalNavigation } from '@/hooks/use-global-navigation'
import router from 'next/router'
import { SnsAccount } from '@/components/root/global-navigation/sns-account'

export const MenuLogout = () => {
  const { hideGlobalNavigation } = useGlobalNavigation()

  const handleClickFaqLink = () => {
    hideGlobalNavigation()
    router.push('/login#faq')
  }

  return (
    <>
      <ul className={styles.main_memu}>
        <li className={styles.item}>
          <Link href="/">
            <a>
              <span className={styles.number}>01</span>
              <span className={styles.text_en}>TOP</span>
              <span className={styles.text_ja}>トップ</span>
            </a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/how-to-play">
            <a>
              <span className={styles.number}>02</span>
              <span className={styles.text_en}>HOW TO PLAY</span>
              <span className={styles.text_ja}>PICKFIVEの遊び方</span>
            </a>
          </Link>
        </li>
        <li className={styles.item}>
          <a onClick={handleClickFaqLink}>
            <span className={styles.number}>03</span>
            <span className={styles.text_en}>FAQ</span>
            <span className={styles.text_ja}>よくあるご質問</span>
          </a>
        </li>
      </ul>
      <ul className={styles.sub_menu}>
        <li className={styles.item}>
          <a href="https://kawasaki-bravethunders.com/" target="_blank" rel="noopener noreferrer">
            川崎ブレイブサンダース
          </a>
        </li>
        <li className={styles.item}>
          <Link href="/agreement">
            <a>重要な説明事項</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/contact">
            <a>お問い合わせ</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/tokushoho">
            <a>特定商取引法に基づく表記</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/terms">
            <a>ユーザ規約</a>
          </Link>
        </li>
        <li className={styles.item}>
          <Link href="/privacy-policy">
            <a>プライバシーポリシー</a>
          </Link>
        </li>
      </ul>
      <SnsAccount />
    </>
  )
}
