import styles from './index.module.scss'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { News } from '@/models/news'
import { NewsListItem } from '@/components/root/news-list/item'
import router from 'next/router'
import { useNewsList } from '@/hooks/use-news-list'
import { useSiteData } from '@/hooks/use-site-data'
import Close from '@/assets/icons/close.svg'
import { CSSTransition } from 'react-transition-group'
import { useScrollLock } from '@/hooks/use-scroll-lock'

export const NewsList = () => {
  const { isShowNewsList, hideNewsList } = useNewsList()
  const { news, setLastDisplayNewsId } = useSiteData()
  const nodeRef = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollTarget, unScrollTarget } = useScrollLock()

  const handleClickNewsItem = useCallback((item: News) => {
    router.push(`/news/${item.id}`)
  }, [])

  const listElements = useMemo<JSX.Element[] | undefined>(() => {
    return news.map((item) => <NewsListItem key={item.id} item={item} onClick={() => handleClickNewsItem(item)} />)
  }, [news, handleClickNewsItem])

  const message = useMemo(() => {
    return <div className={styles.message}>まだお知らせはありません</div>
  }, [])

  const handleClickClose = useCallback(() => {
    hideNewsList()
  }, [hideNewsList])

  const setNewsId = useCallback(() => {
    if (!news.length) return
    setLastDisplayNewsId(news[0].id)
  }, [news, setLastDisplayNewsId])

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (isShowNewsList) {
      scrollTarget(scrollElement.current)
      setNewsId()
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [isShowNewsList])

  return (
    <CSSTransition in={isShowNewsList} timeout={200} unmountOnExit={true} classNames="hoop-fade" nodeRef={nodeRef}>
      <div className={styles.newsList} ref={nodeRef}>
        <div className={styles.heading}>
          <button className={styles.button_close} onClick={handleClickClose}>
            <Close width={24} height={24} />
          </button>
        </div>
        <div className={styles.list} ref={scrollElement}>
          {listElements?.length === 0 ? message : listElements}
        </div>
      </div>
    </CSSTransition>
  )
}
