/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { User } from '@/models/user'
import { User as HoopUser } from '@dena/hoop-proto-client/dist/users_entity_pb'
import { mapDate } from '../parser'

export function mapUser(data: HoopUser.AsObject): User {
  return {
    id: data.id,
    userName: data.userName,
    nickname: data.nickname,
    birthday: data.birthday ? mapDate(data.birthday) : undefined,
    currentPoint: data.currentPoint,
    totalPoint: data.totalPoint,
  }
}
