import styles from './index.module.scss'
import { useCallback, useMemo, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import Close from '@/assets/icons/close.svg'
import { MenuLogin } from '@/components/root/global-navigation/menu-login'
import { MenuLogout } from '@/components/root/global-navigation/menu-logout'
import { useGlobalNavigation } from '@/hooks/use-global-navigation'
import { useSiteData } from '@/hooks/use-site-data'
import { useScrollLock } from '@/hooks/use-scroll-lock'

export const GlobalNavigation = () => {
  const { isShowGlobalNavigation, hideGlobalNavigation } = useGlobalNavigation()
  const { isLogin, isCreateUser, isSetBirthday, hasWallet } = useSiteData()
  const { scrollTarget, unScrollTarget } = useScrollLock()

  const nodeRef = useRef(null)
  const scrollElement = useRef<HTMLDivElement>(null)

  const handleClickClose = useCallback(() => {
    hideGlobalNavigation()
  }, [hideGlobalNavigation])

  const mainMenuElements = useMemo<JSX.Element>(() => {
    if (isLogin && isCreateUser && isSetBirthday && hasWallet) {
      return <MenuLogin />
    }
    return <MenuLogout />
  }, [hasWallet, isCreateUser, isLogin, isSetBirthday])

  useEffect(() => {
    if (!scrollElement.current) {
      return
    }

    if (isShowGlobalNavigation) {
      scrollTarget(scrollElement.current)
    } else {
      unScrollTarget(scrollElement.current)
    }
  }, [isShowGlobalNavigation])

  return (
    <CSSTransition in={isShowGlobalNavigation} timeout={200} unmountOnExit={true} classNames="hoop-fade" nodeRef={nodeRef}>
      <div className={styles.global_nav} ref={nodeRef}>
        <div className={styles.inner} ref={scrollElement}>
          <button className={styles.button_close} onClick={handleClickClose}>
            <Close width={24} height={24} />
          </button>
          {mainMenuElements}
        </div>
      </div>
    </CSSTransition>
  )
}
