import * as Sentry from '@sentry/nextjs'

export const useSentry = () => {
  const setUserIdforSentry = (firebaseUid: string) => {
    Sentry.setUser({ id: firebaseUid })
    return
  }

  const unsetUserIdforSentry = () => {
    Sentry.configureScope((scope) => scope.setUser(null))
    return
  }

  return {
    setUserIdforSentry,
    unsetUserIdforSentry,
  }
}
