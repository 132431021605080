import { doc, FirestoreDataConverter, QueryDocumentSnapshot, DocumentData, setDoc, getDoc } from 'firebase/firestore'
import { getFirestoreClient } from '@/lib/firebase/'

type NewProfileIconData = {
  isDisplayedProfileNewIcon?: boolean
}

const converter: FirestoreDataConverter<NewProfileIconData> = {
  toFirestore: (model: NewProfileIconData): DocumentData => {
    return model
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot): NewProfileIconData => {
    const data = snapshot.data()

    return {
      isDisplayedProfileNewIcon: data.isDisplayedProfileNewIcon,
    }
  },
}

export async function setDisplayedProfileNewIconStatus(uid: string): Promise<void> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private')
  await setDoc(query, { isDisplayedProfileNewIcon: true }, { merge: true })
}

export async function getDisplayedProfileNewIconStatus(uid: string): Promise<boolean | undefined> {
  const firestore = getFirestoreClient()

  const query = doc(firestore, 'versions', 'v1', 'users', uid, 'scope', 'private').withConverter(converter)
  const snapshot = await getDoc(query)
  const data = snapshot.data()

  return data?.isDisplayedProfileNewIcon
}
