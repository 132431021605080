// 販売物
export interface SaleCard {
  id: string
  saleType: SaleType
  periodType: PeriodType
  card: CardInfo
  price: number
  saleCount: number // 販売上限数
  entryCount: number // 申込数
  saleStartedAt: Date
  saleEndedAt: Date
  publishedAt: Date
  closedAt: Date
}

export interface CardInfo {
  id: string
  title: string
  description: string
  player: PlayerInfo
  mainImage: string
  youtubeVideoId?: string
}

export interface PlayerInfo {
  name: string
  englishName: string
  uniformNumber: string
  position: string
  profileImage: string
  officialProfileUrl: string
}

// 販売物の分類
export const SaleType = {
  RAFFLE_DRAWING: '抽選',
  FIRST_SERVED: '先着',
} as const
export type SaleType = typeof SaleType[keyof typeof SaleType]

// 販売期間の分類
export const PeriodType = {
  SPECIAL: '期間限定販売',
  REGULAR: '通常販売',
} as const
export type PeriodType = typeof PeriodType[keyof typeof PeriodType]
