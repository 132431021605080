import { News } from '@/models/news'
import { atom } from 'recoil'

type NewsData = {
  news: News[]
}

type NewsStatusData = {
  lastDisplayNewsId?: string
}

export const newsDataAtom = atom<NewsData>({
  key: 'state/newsData',
  default: {
    news: [],
  },
})

export const NewsStatusDataAtom = atom<NewsStatusData>({
  key: 'state/newsStatusData',
  default: {
    lastDisplayNewsId: undefined,
  },
})
