import { Rarity } from '@/models/rarity'
import { RarityMap, Rarity as HoopRarity } from '@dena/hoop-proto-client/dist/hoop_entity_pb'

export function mapRarity(rarity: RarityMap[keyof RarityMap]): Rarity | undefined {
  switch (rarity) {
    case HoopRarity.RARITY_NORMAL:
      return Rarity.NORMAL
    case HoopRarity.RARITY_RARE:
      return Rarity.RARE
    case HoopRarity.RARITY_SUPER_RARE:
      return Rarity.SUPER_RARE
    case HoopRarity.RARITY_LEGENDARY:
      return Rarity.LEGENDARY
    default:
      return
  }
}
