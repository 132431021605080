import Script from 'next/script'
import { GA_ID } from '@/lib/gtag'
import { GA_4_ID } from '@/lib/gtag'

export const GoogleAnalytics = () => {
  return GA_ID && GA_4_ID ? (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_4_ID}`} strategy="afterInteractive" />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_ID}');
          gtag('config', '${GA_4_ID}');
        `,
        }}
      />
    </>
  ) : null
}
