/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SaleType, SaleCard, CardInfo, PlayerInfo, PeriodType } from '@/models/sale'
import { SaleCard as HoopSaleCard, CardInfo as HoopCardInfo, PlayerInfo as HoopPlayerInfo } from '@dena/hoop-proto-client/dist/app_pb'
import { mapDate, mapImage } from '.'

export function mapSaleCard(data: HoopSaleCard.AsObject | undefined): SaleCard | undefined {
  if (!data) return

  return {
    id: data.id,
    saleType: mapSaleType(data.saleType)!,
    periodType: mapPeriodType(data.isSpecial),
    card: mapCardInfo(data.card)!,
    price: data.price,
    saleCount: data.saleCount, // 販売上限数
    entryCount: data.entryCount, // エントリー数
    saleStartedAt: mapDate(data.saleStartedAt!),
    saleEndedAt: mapDate(data.saleEndedAt!),
    publishedAt: mapDate(data.publishedAt!),
    closedAt: mapDate(data.closedAt!),
  }
}

function mapSaleType(itemType: HoopSaleCard.SaleTypeMap[keyof HoopSaleCard.SaleTypeMap]): SaleType | undefined {
  switch (itemType) {
    case HoopSaleCard.SaleType.SALE_TYPE_FIRST_SERVED:
      return SaleType.FIRST_SERVED
    case HoopSaleCard.SaleType.SALE_TYPE_RAFFLE_DRAWING:
      return SaleType.RAFFLE_DRAWING
    default:
      return
  }
}

function mapPeriodType(isSpecial: boolean): PeriodType {
  if (isSpecial) {
    return PeriodType.SPECIAL
  } else {
    return PeriodType.REGULAR
  }
}

export function mapCardInfo(data: HoopCardInfo.AsObject | undefined): CardInfo | undefined {
  if (!data) return

  return {
    id: data.id,
    title: data.title,
    description: data.desc,
    player: mapPlayerInfo(data.player!)!,
    mainImage: mapImage(data.mainImage!),
    youtubeVideoId: data.youtubeVideoId !== '' ? data.youtubeVideoId : undefined,
  }
}

export function mapPlayerInfo(data: HoopPlayerInfo.AsObject | undefined): PlayerInfo | undefined {
  if (!data) return

  return {
    name: data.name,
    englishName: data.englishName,
    uniformNumber: data.uniformNumber,
    position: data.position,
    profileImage: mapImage(data.profileImage!),
    officialProfileUrl: data.officialProfileUrl,
  }
}
